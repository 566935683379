import React, { useState, useEffect, useContext, useRef } from 'react';
// import theme from '../theme/theme.style';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, CardContent, Avatar, Popover, TextField, Divider, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { getInitials, PATCH } from '../api'
import { Context } from '../Store';
import validatePhoneNumber from 'validate-phone-number-node-js';
import { Formik } from 'formik';
import { Settings as SettingsIcon, LocalHospital, ArrowBackIos } from '@material-ui/icons';
import ErrorMessage from '../components/ErrorMessage';
import Settings from '../components/Settings';
import ReportInfections from '../components/ReportInfections';

interface Values {
  name: string,
  email: string,
  phone: string,
}

interface Props {
  open: boolean,
  onClose: () => void,
  anchorEl: Element | ((element: Element) => Element)
}

export default (props:Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();
  const [context, setContext] = useContext(Context);
  const [edit, setEdit] = useState(false);
  const [hasPassword, setHasPassword] = useState(false);
  const formik = useRef<any>();
  const [screen, setScreen] = useState('profile');

  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  useEffect(() => {
    if (props.open) {
      setEdit(false);
      setScreen('profile');
    }
  }, [props.open])

  function cancelEdit() {
    if (formik && formik.current) {
      formik.current.handleChange('name')(context.profile.name);
      formik.current.handleChange('email')(context.profile.email);
      formik.current.handleChange('phone')(context.profile.phone);
    }
    setEdit(false);
  }

  function validate(values: Partial<Values>) {
    const errors: Partial<Values> = {};

    if (!values.name || values.name.length < 1) {
      errors.name = t('please_enter_your_name');
    }
    if (!values.email || !(/^\s*([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})\s*$/i.test(values.email))) {
      errors.email = t('please_enter_a_valid_email');
    }
    if (!values.phone || !validatePhoneNumber.validate(values.phone)) {
      errors.phone = t('please_enter_your_phone_number');
    }

    return errors;
  }

  async function submit(values: Partial<Values>) {
    setContext(context => ({...context, profile: {
      ...context.profile,
      name: values.name,
      email: values.email,
      phone: values.phone,
    }}));

    await PATCH('/api/user/details', {
      name: values.name,
      email: values.email,
      phone: values.phone,
    });
    
    setEdit(false);
  }


  const profile = (
    <CardContent>
      <Formik
        initialValues={{
          name: context.profile.name,
          email: context.profile.email,
          phone: context.profile.phone,
        }}
        validate={validate}
        onSubmit={submit}
        innerRef={formik}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          <>
            <div>
              <div className={classes.spaceBetween}>
                {edit? <Button className={classes.leftButton} onClick={cancelEdit} color={'secondary'} >{t('cancel')}</Button>: <Button></Button>}
                <Button className={classes.rightButton} onClick={() => {edit?handleSubmit():setEdit(true)}} color={edit?'secondary':'inherit'} >{edit?t('done'):t('edit')}</Button>
              </div>
              <Typography className={classes.title}>{t('my_profile')}</Typography>
            </div>
            

            <div className={classes.avatarContainer}>
              <Avatar className={classes.profileAvatar} >{getInitials(context.profile.name)}</Avatar>
            </div>

            {!edit ? <Typography variant={'h5'}>{values.name}</Typography>
            : <>
              <TextField style={{marginLeft: 24, marginRight: 24}} value={values.name} onChange={handleChange('name')} placeholder={t('full_name')} error={errors.name !== undefined} helperText={errors.name} variant="filled" size="small" />
              {/* <ErrorMessage name={errors.name} /> */}
            </>}

            <div style={{width: theme.spacing(1), height: theme.spacing(1)}} />

            {!edit ?
              <div className={classes.spaceBetweenWithMargin}>
                {/* <div style={classes.smallBox} onClick={() => navigation.navigate('shareContact', {name: context.profile.name})}>
                  <Icon name='qrcode' color={theme.palette.secondary} size={32} />
                  <Typography  style={classes.smallBoxText}>{t('share_contact')}</Typography>
                </div> */}
                <ButtonBase className={classes.smallBox} onClick={() => {setScreen('reportInfection');}}>
                  <LocalHospital color={'primary'} style={{fontSize: 28}} />
                  <Typography className={classes.smallBoxTextRed}>{t('report_infection')}</Typography>
                </ButtonBase>
                <div style={{width: theme.spacing(1)}} />
                <ButtonBase className={classes.smallBox} onClick={() => {setScreen('settings');}}>
                  <SettingsIcon color={'secondary'} style={{fontSize: 28}} />
                  <Typography className={classes.smallBoxText}>{t('settings')}</Typography>
                </ButtonBase>
              </div>
            : null }

            <div style={{width: theme.spacing(1), height: theme.spacing(1)}} />

            <div className={classes.card}>
              {!edit ? <>
                <Typography className={classes.itemTitle}>{t('email')}</Typography>
                <Typography className={classes.item}>{values.email}</Typography>
              </> : <>
                <TextField className={classes.input} label={t('email')} value={values.email} onChange={handleChange('email')} error={errors.email !== undefined} variant="filled" size="small" />
                <ErrorMessage name={errors.email} />
              </> }

              <Divider style={{marginTop: theme.spacing(2), marginBottom: theme.spacing(2)}} />
              
              {!edit ? <>
                <Typography className={classes.itemTitle}>{t('phone')}</Typography>
                <Typography className={classes.item}>{values.phone}</Typography>
                </> : <>
                <TextField className={classes.input} label={t('phone')} value={values.phone} onChange={handleChange('phone')} error={errors.phone !== undefined} variant="filled" size="small" />
                <ErrorMessage name={errors.phone} />
              </> }
            </div>

            {/* <AddPasswordMini hasPassword={hasPassword} setHasPassword={setHasPassword} /> */}
          </>
        )}
      </Formik>
    </CardContent>
  );

  return (
    <Popover
      id={'profile'}
      open={props.open}
      onClose={props.onClose}
      anchorEl={props.anchorEl}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right', }}
      transformOrigin={{vertical: 'top', horizontal: 'right', }}
      classes={{'root': classes.popover, 'paper': classes.profileCard}}
    >
      {screen === 'profile' ? profile:null}
      {screen === 'settings' ? <Settings open={screen === 'settings'} onClose={() => {setScreen('profile')}} /> :null}
      {screen === 'reportInfection' ? <ReportInfections open={screen === 'reportInfection'} onClose={() => {setScreen('profile')}} /> :null}

    </Popover>
  );
};

const useStyles = makeStyles((theme) => ({
  popover: {
    [theme.breakpoints.down('xs')]: {
      left: 0,
      right: 0,
      margin: 0,
    },
  },
  profileCard: {
    width: 300,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  avatarContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  profileAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  rightButton: {
    marginTop: theme.spacing(-1),
    marginRight: theme.spacing(-1),
  },
  leftButton: {
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-1),
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: theme.spacing(-3.5),
    marginBottom: theme.spacing(2),
  },
  spaceBetweenWithMargin:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 6,
  },
  smallBox: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    padding: 6,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    shadow: theme.shadows[3],
    display: 'flex',
    flexDirection: 'column',
  },
  smallBoxText: {
    color: theme.palette.secondary.main,
    fontSize: 11,
    lineHeight: '12px',
    marginTop: 4,
  },
  smallBoxTextRed: {
    color: theme.palette.primary.main,
    fontSize: 11,
    lineHeight: '12px',
    marginTop: 4,
  },
  card: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
  itemTitle: {
    fontSize: 12,
    marginBottom: 4,
    lineHeight: '13px',
    textAlign: 'left',
  },
  item: {
    color: theme.palette.secondary.main,
    fontSize: 17,
    lineHeight: '17px',
    textAlign: 'left',
  },
  input: {
    margin: 0, 
    marginTop: 4,
  },
}));
