import React, { useState, useEffect, useContext, useRef } from 'react';
// import theme from '../theme/theme.style';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, CardContent, Avatar, Popover, TextField, Divider, ButtonBase, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, IconButton, Menu, MenuItem, Snackbar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Batch, Context, Event } from '../Store';
import { Formik } from 'formik';
import { Add as AddIcon } from '@material-ui/icons';
import EditEventsMini from '../components/EditEventsMini';
import EventMini from '../components/EventMini';
import AddEventDialog from './AddEventDialog';
export interface Values {
  name: string,
  sublocation_name: string,
  amount: string,
  counterStart: string,
  capacity: string,
  spaceBetweenGroups: string,
  link?: string,
}

interface Props {
  open: boolean,
  onClose: any,
  link: string,
}


export default (props:Props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();
  const [context, setContext] = useContext(Context);
  const [addDialog, setAddDialog] = useState(false);

  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  function close() {
    props.onClose();
  }

  function addEvent(data:Event) {
    console.error('Not implemented');
    setContext((context) => {
      const temp = context.locations[props.link].events;
      temp.push(data.link);

      return {...context, locations: {...context.locations, [props.link]: {...context.locations[props.link], event: temp}}, events: {...context.events, [data.link]: data}};
    });
  }

  return (
    <Dialog open={props.open} onClose={close} fullWidth maxWidth={'sm'} >
      <DialogTitle>{t('events')}</DialogTitle>
      <DialogContent>

      {context.locations[props.link]?.events.map((link:string, key:number) => {
        return <EventMini locationLink={props.link} link={link} key={key} />
      })}

      <Button onClick={() => {setAddDialog(true)}} className={classes.addButton} fullWidth >
        <div className={classes.buttonContainer}>
          <AddIcon fontSize={'default'} color={'inherit'}/>
          <Typography style={{marginTop: 2,}} >{t('add_event')}</Typography>
        </div>
      </Button>

      </DialogContent>
      <DialogActions>
        <Button color={'inherit'} onClick={close} >{t('cancel')}</Button>
      </DialogActions>

      <AddEventDialog open={addDialog} onClose={() => {setAddDialog(false)}} link={props.link} addEvent={addEvent} />
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  addButton: {
    border: '3px dashed',
    borderColor: theme.palette.grey[500],
    height: 65,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.grey[500],
    padding: theme.spacing(0.5),
  },
}));
