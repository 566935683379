import React, { useState, useEffect, useContext } from 'react';
// import theme from '../theme/theme.style';
import { Redirect, useHistory, useLocation } from 'react-router';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CssBaseline, Button, Typography, Paper, Container, Link, ButtonBase, CardActionArea, Card, CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Context, Event as EventType } from '../Store';
import { KeyboardArrowRight } from '@material-ui/icons';
import SublocationModal from '../modals/SublocationModal';
import Event from '../modals/Event';
import FlatCard from './FlatCard';
import { GET } from '../api';

interface Props {
  locationLink: string,
  link: string,
  onClick: (link:string) => void,
}

export default (props:Props) => {
  const { t } = useTranslation();
  // const history = useHistory();
  // const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  // const [redirect, setRedirect] = useState<string|null>(null);
  const [context, setContext] = useContext(Context);

  useEffect(() => {
    // TODO reduce number of requests by getting all events at once
    async function getEvent() {
      const res = await GET(`/api/e/${props.link}`);
      setContext(context => {
        return {...context, events: {...context.events, [props.link]: res.data}}
      })
    }
    if (!context.events[props.link]) {
      getEvent();
    }
  },[])

  return (
    <FlatCard className={classes.all} style={{borderColor:theme.palette.grey[400]}}>
      <CardActionArea onClick={() => {props.onClick(props.link)}} className={classes.content}>
        <div className={classes.timeBox}>
          <Typography className={classes.date}>{context.events[props.link] && new Date(context.events[props.link]?.time).toLocaleDateString(['de', 'en'], {day: '2-digit', month:'2-digit'})}</Typography>
          <Typography className={classes.time}>{context.events[props.link] && new Date(context.events[props.link]?.time).toLocaleTimeString(['de', 'en'], {hour: '2-digit', minute:'2-digit'})}</Typography>
        </div>
      
        <CardContent className={classes.spaceBetween}>
          <div>
            <Typography align='left'>{context.events[props.link]?.name}</Typography>
            {context.events[props.link]?.booked && <Typography align='left' style={{color: theme.palette.grey[600], fontSize: 13, marginTop: -4 }} >{`${context.events[props.link]?.booked}${context.events[props.link]?.userLimit?'/'+context.events[props.link]?.userLimit:''} ${t('occupied')}`}</Typography>}
          </div>
          
          <KeyboardArrowRight />
        </CardContent>
      </CardActionArea>
    </FlatCard>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    marginBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
    alignItems: 'center',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  timeBox: {
    backgroundColor: theme.palette.grey[400],
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  date: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
    // textShadow: '0px 0px 5px #00000055',
  },
  time: {
    color: 'white',
    // textShadow: '0px 0px 5px #00000055',
  },
}));
