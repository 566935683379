import React, { useState, useEffect } from 'react';
// import theme from '../theme/theme.style';
import { Redirect, useHistory, useLocation } from 'react-router';
import logo from '../images/logo1_large.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CssBaseline, Button, Typography, Paper, Container, Link } from '@material-ui/core';
import { url } from '../api';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;

export default () => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>(null);

  useEffect(() => {
    // console.log(routeLocation)
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  return (
    <footer className={classes.footer}>
      {/* {redirect !== null ? <Redirect to={redirect} /> : null} */}
      <Container maxWidth="lg" className={classes.footerContainer}>
        <Typography variant="body2" color="textSecondary" align="left">
          <Link color="inherit" href="mailto:support@contactscan.de'">support@contactscan.de</Link>
        </Typography>

        <Typography variant="body2" color="textSecondary" align="center">
          {t('copyright')}{' © '}
          <Link color="inherit" href="/">Darobo UG</Link>
          {' '}{new Date().getFullYear()}
        </Typography>
        
        <Typography variant="body2" color="textSecondary" align="right"  >
          <Link color="inherit" onClick={() => {setRedirect('/imprint')}} >{t('imprint')}</Link>{' · '}
          <Link color="inherit" onClick={() => {setRedirect('/terms')}} >{t('terms')}</Link>{' · '}
          <Link color="inherit" onClick={() => {setRedirect('/privacy')}} >{t('privacy')}</Link>
        </Typography>
      </Container>
    </footer>
  );
};

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0),
  },
  footerContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flex: {
    display: 'flex',
  }
}));
