import React, { useState, useEffect, useContext, useRef } from 'react';
// import theme from '../theme/theme.style';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, CardContent, Avatar, Popover, TextField, Divider, ButtonBase, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, IconButton, Menu, MenuItem, Snackbar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Batch, Context, Event } from '../Store';
import { Field, Formik } from 'formik';
import { Add as AddIcon } from '@material-ui/icons';
import EditEventsMini from '../components/EditEventsMini';
import EventMini from '../components/EventMini';
import { POST } from '../api';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { CheckboxWithLabel } from 'formik-material-ui';

export interface Values {
  name: string,
  time: string,
  userLimit: string,
  isPublic: boolean | string,
  limit?: boolean,
}

interface Props {
  open: boolean,
  onClose: any,
  link: string,
  addEvent: (data:Event) => void,
}


export default (props:Props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();
  const [context, setContext] = useContext(Context);
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  function close() {
    setMessage('');
    props.onClose();
  }

  function validate(values: Partial<Values>) {
    const errors: Partial<Values> = {};

    if (!values.name || values.name.length < 1) {
      errors.name = t('required');
    }
    if (!time) {
      errors.time = t('required');
    }
    if (values.userLimit && (isNaN(+ values.userLimit) || +values.userLimit !== parseInt(values.userLimit))) {
      errors.userLimit = t('please_enter_an_integer');
    }

    return errors;
  }

  async function submit(values: Partial<Values>) {
    setSubmitting(true);
    try {
      let res = await POST('/api/e/create', {
          name: values.name,
          location: props.link,
          time: time,
          userLimit: values.userLimit ? +values.userLimit : null,
          isPublic: values.isPublic,
        });
      
      if (res.status === 200) {
        props.addEvent(res.data);

        setMessage(t('success'));
        close()
      } else {setMessage(res.data.msg || res.data)}
    } catch (err) {console.error(err)}
    setSubmitting(false);
  }

  return (
    <Dialog open={props.open} onClose={close} fullWidth maxWidth={'sm'} >
      <DialogTitle>{t('add_event')}</DialogTitle>
      <Typography color={'error'}>{message}</Typography>

      <Formik
        initialValues={{
          name: '',
          time: '',
          userLimit: '',
          isPublic: false,
          limit: false,
        }}
        validate={validate}
        onSubmit={submit}
        // innerRef={formikContext}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          <>
            <DialogContent>
              <TextField label={t('name')} value={values.name} onChange={handleChange('name')} error={errors.name?.length>0} helperText={errors.name} fullWidth />
              <div style={{height: theme.spacing(2)}} />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker value={time} onChange={setTime} format="dd.MM.yyyy hh:mm" fullWidth label={t('time')} ampm={false} helperText={errors.time} />
              </MuiPickersUtilsProvider>
              
              <div style={{height: theme.spacing(2)}} />
              <Field component={CheckboxWithLabel} type="checkbox" name="limit" Label={{ label: t("custom_limit") }} style={{alignSelf: 'left'}} />
              {values.limit ? 
                <TextField label={t('custom_limit')} value={values.userLimit} onChange={handleChange('userLimit')} error={errors.userLimit?.length>0} helperText={errors.userLimit} fullWidth />
              :
                <DialogContentText>{t('default_the_capacity_is_limited_by_the_capacity_of_the_sublocations')}</DialogContentText>
              }

            </DialogContent>
            <DialogActions>
              <Button color={'inherit'} onClick={close} >{t('cancel')}</Button>
              <Button color={'primary'} variant={'contained'} onClick={() => handleSubmit()} disabled={( submitting || values.name==='')} >{t('create')}</Button>
            </DialogActions>
          </>
        )}
      </Formik>

      {/* <DialogActions>
        <Button color={'inherit'} onClick={close} >{t('cancel')}</Button>
      </DialogActions> */}

    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  addButton: {
    border: '3px dashed',
    borderColor: theme.palette.grey[500],
    height: 100,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.grey[500],
    padding: theme.spacing(0.5),
  },
}));
