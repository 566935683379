import React, { useState, useEffect } from 'react';
// import theme from '../theme/theme.style';
// import { Redirect } from 'react-router';
import logo from '../images/logo1_large.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, Container, Link } from '@material-ui/core';
import AppBar from '../components/AppBar';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>(null);

  useEffect(() => {
    console.log(i18n.language)
  }, []);

  useEffect(() => {
    setRedirect(null);
  }, [redirect]);

  const german = (
    <>
      <Typography variant={'subtitle1'} >Angaben gemäß § 5 TMG</Typography>
      <Typography variant={'body1'} >Darobo UG (haftungsbeschränkt)</Typography>
      <Typography variant={'body1'} >Hackerscher Markt 4 / Neue Promenade 3</Typography>
      <Typography variant={'body1'} gutterBottom >10178 Berlin</Typography>

      <Typography variant={'body1'} gutterBottom >
        <Link color="inherit" href="mailto:support@contactscan.de'" >support@contactscan.de</Link>
      </Typography>
      
      <Typography variant={'subtitle1'} >Vertreten durch:</Typography>
      <Typography variant={'body1'} gutterBottom >David Bonello</Typography>

      <Typography variant={'subtitle1'} >Registereintrag:</Typography>
      <Typography variant={'body1'} >Eintragung im Handelsregister.</Typography>
      <Typography variant={'body1'} >Registergericht: Berlin Amtsgericht Berlin (Charlottenburg)</Typography>
      <Typography variant={'body1'} gutterBottom >Registernummer: HRB 223289</Typography>

      <Typography variant={'subtitle1'} >Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</Typography>
      <Typography variant={'body1'} gutterBottom >David Bonello, Hackerscher Markt 4 / Neue Promenade 3, 10178 Berlin</Typography>
      
      <Typography variant={'h5'} >{'Haftungsausschluss:'}</Typography>
      <Typography variant={'subtitle1'} >{'Haftung für Inhalte'}</Typography>
      <Typography variant={'body2'} paragraph gutterBottom>
        {'Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.'}
      </Typography>
      <Typography variant={'subtitle1'} >{'Haftung für Links'}</Typography>
      <Typography variant={'body2'} paragraph gutterBottom >
        {'Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.'}
      </Typography>
      <Typography variant={'subtitle1'} >{'Urheberrecht'}</Typography>
      <Typography variant={'body2'} paragraph gutterBottom >
        {'Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.'}
      </Typography>
      <Typography variant={'subtitle1'} >{'Datenschutz'}</Typography>
      <Typography variant={'body2'} paragraph >
        {'Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.'}
      </Typography>
      <Typography variant={'body2'} paragraph >
        {'Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.'}
      </Typography>
      <Typography variant={'body2'} paragraph gutterBottom >
        {'Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.'}
      </Typography>
      
      <Typography variant={'body2'} >
        Impressum vom <Link href="https://www.impressum-generator.de">Impressum Generator</Link> der <Link href="https://www.kanzlei-hasselbach.de/standorte/bonn/">Kanzlei Hasselbach, Bonn</Link> 
      </Typography>
    </>
  );

  const english = (
    <>
      <Typography variant={'subtitle1'} >Information according to § 5 TMG</Typography>
      <Typography variant={'body1'} >Darobo UG (haftungsbeschränkt)</Typography>
      <Typography variant={'body1'} >Hackerscher Markt 4 / Neue Promenade 3</Typography>
      <Typography variant={'body1'} gutterBottom >10178 Berlin</Typography>

      <Typography variant={'body1'} gutterBottom >
        <Link color="inherit" href="mailto:support@contactscan.de'" >support@contactscan.de</Link>
      </Typography>
      
      <Typography variant={'subtitle1'} >Represented by:</Typography>
      <Typography variant={'body1'} gutterBottom >David Bonello</Typography>

      <Typography variant={'subtitle1'} >Trade Register:</Typography>
      <Typography variant={'body1'} gutterBottom >Court: Berlin Charlottenburg, Number:  HRB 223289</Typography>

      <Typography variant={'subtitle1'} >Responsible for all contents according to § 55 Abs. 2 RStV:</Typography>
      <Typography variant={'body1'} gutterBottom >David Bonello, Hackerscher Markt 4 / Neue Promenade 3, 10178 Berlin</Typography>
      
      <Typography variant={'subtitle1'} >Dispute Settlement</Typography>
      <Typography variant={'body1'} gutterBottom >The Commission of the European Communities manages an online platform for dispute settlement purposes at: https://ec.europa.eu/consumers/odr. We are not obliged or willing to take part in any such online consumer dispute settlements. Our E-mail address is listed at the top of this page under “Imprint”.</Typography>

      <Typography variant={'h5'} >{'Exclusion of Liability:'}</Typography>
      <Typography variant={'subtitle1'} >{'Content Liability (Legal Disclaimer)'}</Typography>
      <Typography variant={'body2'} paragraph gutterBottom>
        {'The contents of our pages were created with the greatest care. However, we cannot assume any liability for the correctness, completeness, and topicality of the contents. As a service provider, we are responsible according to Section 7 paragraph 1 of the German Telemedia Act (TMG) for our own content on these pages in accordance with general laws. However, according to Sections 8 to 10 of the German Telemedia Act (TMG) and as a service provider, we are not obliged to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. Obligations to remove or block the use of information in accordance with general laws remain unaffected by such action. However, liability in this respect is only possible from the time of knowledge of a concrete violation of the law. The content will be removed as soon as we become aware of any infringement.'}
      </Typography>
      <Typography variant={'subtitle1'} >{'Link Liability (Legal Disclaimer)'}</Typography>
      <Typography variant={'body2'} paragraph gutterBottom >
        {'Our offer contains links to external websites of third parties on the contents of which we are not influential. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the contents of the linked pages. The linked pages were checked for possible legal infringements at the time of creating the link. Illegal contents were not recognizable at the time of setting the link. A permanent control of the contents of the linked pages is not reasonable without concrete evidence of an infringement. Such links will be removed as soon as we become aware of any legal infringements.'}
      </Typography>
      <Typography variant={'subtitle1'} >{'Intellectual Property Rights'}</Typography>
      <Typography variant={'body2'} paragraph gutterBottom >
        {'The contents and works on these pages created by the site operators are subject to German copyright law. Duplication, processing, distribution and any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator. Downloads and copies of these pages are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, the contents of third parties are marked as such. Should you nevertheless become aware of copyright infringement, please inform us accordingly. Such content will be removed immediately upon notification.'}
      </Typography>
      <Typography variant={'subtitle1'} >{'Data Protection'}</Typography>
      <Typography variant={'body2'} paragraph gutterBottom >
        {'The use of our website is generally possible without providing personal data. Any personal data (e.g. name, address or e-mail address) collected on our website is always on a voluntary basis. This data will not be passed on to third parties without your express consent. We would like to point out that data transmission over the Internet (e.g. communication by e-mail) can be subject to security vulnerabilities. Complete protection of the data against access by third parties is not possible. The use of contact data published within the scope of the imprint obligation by third parties to send unsolicited advertising and information materials is hereby expressly prohibited. The operators of this website explicitly reserve the right to take legal action against unsolicited mailing or e-mailing of spam and other similar advertising materials.'}
      </Typography>
      
      <Typography variant={'body2'} >
        Part of this “Imprint” section has been written using <Link href="https://www.impressum-generator.de">impressum-generator.de</Link> of <Link href="https://www.kanzlei-hasselbach.de/standorte/bonn/">Hasselbach, Attorneys at Law</Link>
      </Typography>
    </>
  );

  return (
    <div className={classes.root}>
      <AppBar />
      <div className={classes.toolbar} />

      <Container className={classes.container}>
        <Typography variant={"h1"} gutterBottom >{t('imprint')}</Typography>

        {i18n.language && i18n.language.split('_')[0] == 'de' ? german : english}

      </Container>

      <Footer />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  container: {
    textAlign: 'left',
    paddingBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: 'bold',
  },
}));
