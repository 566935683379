import React, { useEffect, } from 'react';
import { BrowserRouter as Router, Redirect, Route, useHistory } from 'react-router-dom';
// import { Redirect } from 'react-router';
import Store from './Store';
import './App.css';
import './theme/style.css';
import './i18n';
import { useTranslation } from 'react-i18next';
// import { GET } from './api';
// import { Context } from './Store';
import { CookiesProvider } from 'react-cookie';

import Home from './screens/Home';
import TicketScreen from './screens/TicketScreen';
import LocationNotFound from './screens/LocationNotFound';
import Agb from './screens/Agb';
import Imprint from './screens/Imprint';
import Terms from './screens/Terms';
import Privacy from './screens/Privacy';
import Support from './screens/Support';
import Login from './screens/Login';
import Main from './screens/Main';
import Locations from './screens/Locations';
import Scan from './screens/Scan';
import EventScreen from './screens/EventScreen';

const App: React.FC = () => {
  const { t } = useTranslation();
  // const history = useHistory();

  // useEffect(() => {
  //   history.push('/')
  // }, [])

  // const [context, setContext] = useContext<any>(Context);
  // const [redirect, setRedirect] = useState('none');

  // useEffect(() => {
    
  //   async function fetch() {
  //     const response = (await GET('/api/issue/all')).data;
  //     setContext({ ...context, issues: response, loaded: true });
  //   }
  //   if (context.loaded === false) {
  //     fetch();
  //   }
  // }, []);

  return (
    <Store>
      <CookiesProvider>
        <Router>
          <div className="App">
            <div style={styles.screens}>
              {/* <Route
                exact
                path="/"
                render={(props: any) => <Redirect to={"/home"} />}
              /> */}

              {/* Home Screen */}
              <Route exact path="/" render={(props: any) => <Home />} />

              {/* Location Screen */}
              <Route path="/l/" render={(props: any) => <TicketScreen path={props.location.pathname} />} />
              <Route path="/e/" render={(props: any) => <EventScreen path={props.location.pathname} />} />

              {/* Location not found screen */}
              <Route exact path="/notFound" render={(props: any) => <LocationNotFound />} />

              {/* Leagal */}
              <Route exact path="/imprint" render={(props: any) => <Imprint />} />
              <Route exact path="/terms" render={(props: any) => <Terms />} />
              <Route exact path="/privacy" render={(props: any) => <Privacy />} />
              <Route exact path="/support" render={(props: any) => <Support />} />

              {/* Login */}
              <Route exact path="/login" render={(props: any) => <Login />} />
              <Route exact path="/register" render={(props: any) => <Login />} />

              {/* App */}
              {/* <Route exact path="/main" render={(props: any) => <Main />} /> */}
              <Route exact path="/main" render={(props: any) => <Redirect to="/locations" />} />
              <Route exact path="/locations" render={(props: any) => <Locations />} />
              <Route exact path="/scan" render={(props: any) => <Scan />} />

            </div>
          </div>
        </Router>
      </CookiesProvider>
    </Store>
  );
};

const styles = {
  screens: {
    //marginLeft: 80,
  } as React.CSSProperties,
};

export default App;
