import React, { useState, useEffect, useContext } from 'react';
// import theme from '../theme/theme.style';
import logo from '../images/logo1_large.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, Container, Link, CssBaseline, FormControlLabel, Grid, Checkbox } from '@material-ui/core';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Context } from '../Store';
import { GET, POST, setAccess, setRefresh } from '../api';
import validatePhoneNumber from 'validate-phone-number-node-js';
import {Formik, Field} from 'formik';
import {TextField, CheckboxWithLabel} from 'formik-material-ui';
import ErrorMessage from '../components/ErrorMessage';
import { ErrorSharp } from '@material-ui/icons';

interface Values {
  name: string,
  email: string,
  phone: string,
  password: string,
  agb: boolean,
  remember: boolean,
}

interface ErrorValues {
  name: string,
  email: string,
  phone: string,
  password: string,
  agb: string,
}

export default () => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();

  const [context, setContext] = useContext(Context);
  const [login, setLogin] = useState(routeLocation.pathname === '/login');
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  async function submit(values: Partial<Values>) {
    setSubmitting(true);
    try {
      let res;
      if (login) {
        res = await POST('/api/user/login', {
          email: values.email,
          password: values.password,
        });
      } else {
        res = await POST('/api/user/register', {
          name: values.name,
          email: values.email,
          phone: values.phone,
          agb: values.agb,// === 'true',
          password: values.password,
        });
      }

      if (res.status === 200) {
        if (values.remember) await setRefresh(res.data.refreshToken);
        await setAccess(res.data.token);

        if (login) {
          // get user data
          let dataRes = await GET('/api/user/details');
          if (dataRes.status === 200) {      
            setContext(context => ({...context, profile: {...context.profile, ...dataRes.data}, loggedIn: true}));
            // await changeJSON('user', dataRes.data);
          }
        } else {
          // await changeJSON('user', {
          //   name: values.name,
          //   email: values.email,
          //   phone: values.phone,
          // });
          setContext((context) => {
            return {...context, profile: {name:values.name, email:values.email, phone:values.phone}, loggedIn: true}
          });
        }

        setRedirect('/main');

      } else {setMessage(res.data.msg || res.data)}
    } catch (err) {console.error(err)}
    setSubmitting(false);
  }

  function validate(values: Partial<Values>) {
    const errors: Partial<ErrorValues> = {};

    if (!login && (!values.name || values.name.length < 1)) {
      errors.name = t('please_enter_your_name');
    }
    if (!values.email || !(/^\s*([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})\s*$/i.test(values.email))) {
      errors.email = t('please_enter_a_valid_email');
    }
    if (!login && (!values.phone || !validatePhoneNumber.validate(values.phone))) {
      errors.phone = t('please_enter_your_phone_number');
    }
    if (!values.password || !(/^.{6,}$/i.test(values.password))) {
      errors.password = t('password_has_to_be_at_least_6_characters_long');
    }
    if (!login && !values.agb ) {
      errors.agb = t('please_accept_the_terms_and_conditions');
    }

    return errors;
  }

  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Container className={classes.container} maxWidth="xs">
        <img src={logo} width={window.innerWidth-24} height={50} className={classes.logo} onClick={() => {setRedirect('/')}} />

        <Formik
          initialValues={{
            name: '',
            email: '',
            phone: '',
            password: '',
            agb: false,
            remember: true,
          }}
          validate={validate}
          onSubmit={submit}
          style={{flex: 1, }}
        >
          {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
            <div className={classes.card}>
              {/* <CardContent> */}
                <Typography component="h1" variant="h5" className={classes.login}>{login ? t('login') : t('register')}</Typography>
                <Typography className={classes.message}>{message}</Typography>

                {!login ? <Field margin="normal" variant="outlined" component={TextField} name="name" type="name" label={t('name')} fullWidth disabled={submitting} size={'small'} /> :null}
                <Field margin="normal" variant="outlined" component={TextField} name="email" type="email" label={t('email')} autoFocus fullWidth disabled={submitting} size={'small'} />
                {!login ? <Field margin="normal" variant="outlined" component={TextField} name="phone" type="phone" label={t('phone')} fullWidth disabled={submitting} size={'small'} /> :null}
                <Field margin="normal" variant="outlined" component={TextField} name="password" type="password" label={t('password')} fullWidth disabled={submitting} size={'small'} />
                
                {login ? 
                  // <FormControlLabel control={<Checkbox value="remember" color="primary" />} label={t("remember_me")} />
                  <Field component={CheckboxWithLabel} type="checkbox" name="remember" Label={{ label: t("remember_me") }} />
                : 
                  <>
                    <FormControlLabel control={<Checkbox value="agb" color="primary" />} checked={values.agb} onClick={handleChange('agb')} label={
                      <Typography align={'left'} >
                        {t('accept_the')+' '}<Link className={classes.agbLink} onClick={() => setRedirect('/terms')} >{t('terms_and_conditions')}</Link>
                        {' '+t('and_the')+' '}<Link className={classes.agbLink} onClick={() => setRedirect('/privacy')} >{t('privacy_polocy')}</Link>
                      </Typography>
                    } /> 
                    <ErrorMessage name={errors.agb} />
                </>
                }

                <Button type="submit" fullWidth variant="contained" color="primary" onClick={() => handleSubmit()} className={classes.submit}>{login ? t('login') : t('register')}</Button>
                <Grid container>
                  <Grid item>
                    <Link href="#" variant="body2">{t('forgot_password')}</Link>
                  </Grid>
                  <Grid item xs />
                  <Grid item>
                    <Link onClick={() => setLogin(!login)} variant="body2">
                      {login ? t('no_account_register') : t('already_have_an_account_log_in')}
                    </Link>
                  </Grid>
                </Grid>

                {/* {login ? <Typography className={classes.disclamer}>{t('dont_have_a_password_set_it_up_in_the_settings_on_your_other_device')}</Typography>:null} */}

              {/* </CardContent> */}
            </div>
          )}
        </Formik>
          
      </Container>
      <Footer />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {

  },
  container: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    paddingBottom: 12,
    marginBottom: 24,
  },
  spaceBetween: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  login: {
    textAlign: 'center',
    marginTop: 12,
  },
  logo: {
    marginTop: 12,
    marginBottom: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  disclamer: {
    color: theme.palette.grey['800'],
    marginHorizontal: 24,
    // fontSize: theme.typography.medium,
    marginTop: 12,
  },
  message: {
    color: theme.palette.error.main,
    textAlign: "center",
    // fontSize: theme.typography.medium,
    fontWeight: 'bold',
  },
  agbContainer: {
    marginTop: 24,
    marginBottom: 0,
  },
  agbText: {
    // fontSize: theme.typography.medium,
    marginLeft: 12,
    // color: theme.black,
    marginRight: 12,
    flexShrink: 1,
  },
  agbLink: {
    marginTop: 0,
    color: 'blue',
    // fontSize: theme.typography.medium,
    textDecorationLine: 'underline'
  },
  agbWarning: {
    // fontSize: theme.typography.small,
    color: theme.palette.error.main,
  },
  hide: {
    display: 'none',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
  },
}));
