import React, { useState, useEffect, useContext, useRef } from 'react';
// import theme from '../theme/theme.style';
import { Button, Typography, Box, Card, CardMedia, CardContent, CardActions, MenuItem, Container, useTheme, Menu } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';
// import { ValuesOfCorrectTypeRule } from 'graphql';
import { GET, getItem, POST, setItem } from '../api';
import logo from '../images/logo1_large.svg';
import { Redirect, useHistory, useLocation } from 'react-router';
import { Context } from '../Store';
import Footer from '../components/Footer';
import validatePhoneNumber from 'validate-phone-number-node-js';
import { useTranslation } from 'react-i18next';

interface Props {
  path: string,
}

interface Values {
  name: string,
  email: string,
  phone: string,
  sublocation: string,
}


export default (props: Props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [locationId, setLocationId] = useState(props.path.substring(3));
  const [redirect, setRedirect] = useState<string|null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [currentValues, setCurentValues] = useState({name: '', email:'', phone:'',});
  const [submittedTime, setSubmittedTime] = useState(new Date())
  const [context, setContext] = useContext(Context);
  const [loggedIn, setLoggedIn] = useState(false);
  const [submittedData, setSubmittedData] = useState<Partial<Values>>({})
  const formik = useRef<any>();
  const [languageMenu, setLanguageMenu] = useState<null | HTMLElement>(null);
  const [language, setLanguage] = useState(i18n.language);
  const [languageLoading, setLanguageLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({name: '', picture: '', subLocation: '', subLocationLink: '', link: '', subLocations: []});

  useEffect(() => {
    async function fetch() {
      const res = await GET('/api/l/'+locationId);

      if (res.status === 200) setData(res.data);
      else {
        console.error('Location does not exist');
        setRedirect('/notFound');
      }
      setLoading(false);
    }

    if (loading) fetch();

    if (context.profile.name !== undefined && context.profile.name !== '' && context.profile.email !== undefined && context.profile.email !== '') {
      setLoggedIn(true)
    }
  }, []);

  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  useEffect(() => {
    if (formik && formik.current && context.profile.name !== undefined && context.profile.name !== '' && context.profile.email !== undefined && context.profile.email !== '') {
      formik.current.handleChange('name')(context.profile.name);
      formik.current.handleChange('email')(context.profile.email);
      formik.current.handleChange('phone')(context.profile.phone);
      setLoggedIn(true)
    }
  }, [context.profile])

  useEffect(() => {
    if (!languageLoading) {
      i18n.changeLanguage(language);
      setItem('language', language);
    } else {
      async function temp() {
        setLanguage(await getItem('language'));
      }
      temp();
    }
    setLanguageLoading(false);
  }, [language]);

  function validate(values:Partial<Values>) {
    const errors: Partial<Values> = {};
    if (!values.name || values.name.length < 1) {
      errors.name = t('please_enter_your_name');
    }
    if (!values.email || !(/^\s*([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})\s*$/i.test(values.email))) {
      errors.email = t('please_enter_a_valid_email');
    }
    if (!values.phone || !validatePhoneNumber.validate(values.phone)) {
      errors.phone = t('please_enter_your_phone_number');
    }


    if (Object.keys(errors).length > 0) {
      setLoggedIn(false);
    }
    return errors;
  }

  async function submit(values: Partial<Values>, {setSubmitting}) {
    setSubmittedData(values);
    try {
      let res = await POST('/api/t', {
        location: data.link, 
        name: values.name,
        email: values.email,
        phone: values.phone,
        subLocation: values.sublocation.length > 0 ? values.sublocation : data.subLocationLink,
      });
      if (res.status === 200) {
        setSubmittedTime(new Date());
        setSubmitted(true);

        setContext((context) => {
          const temp = context.tickets;
          temp[res.data.ticket.time] = res.data.ticket;

          const temp2 = context.ticketOrder;
          temp2.unshift(res.data.ticket.time);
          return {...context, tickets: temp, ticketOrder: temp2}
        })

      } else { console.log(res.data.msg || res.data); }
    } catch (err) { console.error(err) }

    setSubmitting(false);
  }

  const from = (
    <CardContent>
      <Formik
        initialValues={{
          name: context.profile.name,
          email: context.profile.email,
          phone: context.profile.phone,
          sublocation: '',
        }}
        validate={validate}
        onSubmit={submit}
        innerRef={formik}
      >
        {({submitForm, isSubmitting, touched, errors, values}) => (
          <Form>
            {loggedIn ? <Typography>{t('please_enter_your_information')}</Typography> : null}
            
            {data.subLocations !== undefined &&data.subLocations.length > 0 ?
              <Box margin={0}><Field component={TextField} select name="sublocation" label="Sublocation" fullWidth disabled={loading} align="left" >
                <MenuItem value={''} >{'None'}</MenuItem>
                {data.subLocations.sort((a:any,b:any)=>{return a.name.localeCompare(b.name)}).map((subLocation:any, key:number) => {
                  return <MenuItem value={subLocation.link} key={subLocation.name}>{subLocation.name}</MenuItem>
                })}
              </Field></Box>
            :null}

            {loggedIn ?
              <Card className={classes.loggedInBox}>
                <CardContent>
                  <Typography variant="h5" className={classes.displayName}>{values.name}</Typography>
                  <Typography className={classes.displayEmail}>{values.email}</Typography>
                  <Typography className={classes.displayPhone}>{values.phone}</Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" className={classes.changeButton} onClick={() => setLoggedIn(false)}>{t('change')}</Button>
                </CardActions>
              </Card> 
            : <>
              <div style={{height:theme.spacing(2)}}></div>
              <Field component={TextField} name="name" type="name" label="Name" fullWidth disabled={loading} />
              <div style={{height:theme.spacing(1)}}></div>
              <Field component={TextField} name="email" type="email" label="Email" fullWidth disabled={loading} />
              <div style={{height:theme.spacing(1)}}></div>
              <Field component={TextField} name="phone" type="phone" label="Phone" fullWidth disabled={loading} />
            </>}
            
            <div style={{height:theme.spacing(4)}}></div>

            <Button variant="contained" color="primary" disabled={isSubmitting || loading} onClick={submitForm} fullWidth >Submit</Button>
          </Form>
        )}
      </Formik>
    </CardContent>
  );

  const confirmation = (
    <CardContent>
      <Typography color="primary" variant="h4" >{currentValues.name}</Typography>
      <Typography>{currentValues.email}</Typography>
      <Typography>{currentValues.phone}</Typography>

      <CheckCircle style={{color: theme.palette.success.main}} className={classes.checkIcon} />

      <Typography className={classes.time} >{submittedTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</Typography>
      <Typography className={classes.date} >{submittedTime.toLocaleDateString([])}</Typography>

      { !context.loggedIn ? <>
        <Button color="primary" variant="contained" fullWidth onClick={() => {setRedirect('/register')}} >{t('faster_checkin_create_account')}</Button>
        <Button variant="text" fullWidth onClick={() => {setRedirect('/login')}} >{t('login')}</Button>
      </> :
        <Button color="primary" variant="contained" fullWidth onClick={() => {setRedirect('/main')}} >{t('done')}</Button>
      }
      
    </CardContent>
  );

  return (
    <div className={classes.background}>
      <Container className={classes.container}>
        <img src={logo} className={classes.logo} alt="" onClick={() => {setRedirect('/')}} />

        <Card className={classes.card}>
          <CardMedia image={data.picture} className={classes.media} >
            <Typography align='left' variant='h5' className={classes.locationTitle} >{data.name}</Typography>
          </CardMedia>

          <Typography variant={'subtitle1'} align="left" className={classes.locationSubTitle} >{'subLocation' in data ? data.subLocation :null}</Typography>

          {submitted ? confirmation : from}
          
          {!submitted && !context.loggedIn ? 
            <CardActions>
              <Button color="primary" onClick={() => {setRedirect('/login')}} >{t('login')}</Button>
              <Button onClick={() => {setRedirect('/register')}} >{t('register')}</Button>
            </CardActions>
          :null}
          
        </Card>

        <Button onClick={(e) => {setLanguageMenu(e.currentTarget)}} aria-controls="languageMenu" aria-haspopup="true" >
          {t('change_language')}
        </Button>
        <Menu
          id="languageMenu"
          anchorEl={languageMenu}
          keepMounted
          open={Boolean(languageMenu)}
          onClose={() => {setLanguageMenu(null)}}
          onClick={(e) => {console.log(e.currentTarget)}}
        >
          <MenuItem value={'en'} onClick={() => {setLanguage('en'); setLanguageMenu(null)}} >{t('english')}</MenuItem>
          <MenuItem value={'de'} onClick={() => {setLanguage('de'); setLanguageMenu(null)}} >{t('german')}</MenuItem>
        </Menu>
        
      </Container>

      <Footer />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  background: {
    
  },
  container: {
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  media: {
    height: 120,
    display: 'flex',
    flexDirection: 'column-reverse',
    backgroundColor: theme.palette.grey['300'],
  },
  logo: {
    margin: 12,
    height: 60,
    maxWidth: '100%'
  },
  card: {
    [theme.breakpoints.up('sm')]: {
      width: 600,
    },
    flex: 1,
    width: '100%',
    maxWidth: '600px',
  },
  locationTitle: {
    color: 'white',
    textShadow: '1px 1px 6px rgba(0, 0, 0, 0.85)',
    marginLeft: theme.spacing(1),
  },
  locationSubTitle: {
    marginLeft: theme.spacing(1),
  },
  checkIcon: {
    marginTop: 24,
    marginBottom: 24,
    fontSize: 96,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  logoTitle: {
    fontSize: 32,
    fontWeight: 'bold',
  },
  logoTitleRed: {
    color: theme.palette.primary.main,
  },
  time: {
    textAlign: 'center',
    fontsize: 32,
    maringTop: 12,
    fontWeight: 'bold',
  },
  date: {
    textAlign: 'center',
    fontsize: 32,
    marginBottom: 24,
  },
  displayName: {
    // margin: 12,
    // marginBottom: 0,
    // fontSize: theme.font_title,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  displayEmail: {
    textAlign: "center",
    color: theme.palette.grey[800],
    fontSize: 17,
  },
  displayPhone: {
    textAlign: "center",
    color: theme.palette.grey[800],
    marginTop: 4,
    fontSize: 17,
  },
  loggedInBox: {
    marginTop: 12,
    // borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[300],
    // padding: theme.spacing(2),
  },
  changeButton: {
    // textAlign: 'right',
    // paddingHorizontal: 6,
    // color: theme.palette.grey[800],
    // fontSize: 15,
  },
}));