import React, { useState, useEffect, useContext, useRef } from 'react';
// import theme from '../theme/theme.style';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, CardContent, Avatar, Popover, TextField, Divider, ButtonBase, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, IconButton, Menu, MenuItem, Snackbar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { clearAll, DELETE, getInitials, getItem, getRefresh, PATCH, POST, setItem } from '../api'
import { Context, Sublocation } from '../Store';
import validatePhoneNumber from 'validate-phone-number-node-js';
import { Formik } from 'formik';
import { Settings as SettingsIcon, LocalHospital, ArrowBackIos, MoreVert } from '@material-ui/icons';
import ErrorMessage from '../components/ErrorMessage';
import { Alert, AlertTitle } from '@material-ui/lab';

export interface Values {
  name: string,
  capacity: string,
  spaceBetweenGroups: string,
}

interface Props {
  open: boolean,
  onClose: any,
  locationLink: string,
  data: Sublocation,
  setData: (data: Partial<Sublocation>) => void,
  link: string,
}


export default (props:Props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();
  const [context, setContext] = useContext(Context);

  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [errorSnackBar, setErrorSnackBar] = useState(false);
  
  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  const deleteDialogComponent = ( 
    <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
      <DialogTitle>{t('delete_sublocation')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('warning_this_action_is_not_reversable_we_may_keep_some_data_for_several_weeks_after_deletion_if_required_by_law')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color={'inherit'} onClick={() => {setDeleteDialog(false)}} >{t('cancel')}</Button>
        <Button color={'primary'} onClick={async () => {
          const res = await DELETE('/api/l/sublocation', {link: props.link});
          if (res.status === 200) {
            // remove sublocation from context
            setContext((context) => {
              let temp = context.locations[props.locationLink].sublocations.filter((sublocation) => sublocation.link !== props.link);
              return {...context, locations: {...context.locations, [props.locationLink]: {...context.locations[props.locationLink], sublocations: temp}}};
            })

            // go back
            props.onClose();
          } else {
            console.log(res.data.msg || res.data)
            setErrorSnackBar(true);
          }
        }} >{t('delete')}</Button>
      </DialogActions>
    </Dialog>
  );

  const menuComponent = (
    <Menu
      id="menu"
      anchorEl={anchorEl}
      // keepMounted
      open={Boolean(anchorEl)}
      onClose={() => {setAnchorEl(null);}}
    >
      <MenuItem onClick={() => {setDeleteDialog(true); setAnchorEl(null);}}>{t('delete')}</MenuItem>
    </Menu>
  );

  async function submit(values: Partial<Values>) {
    setSubmitting(true);

    try {
      const res = await PATCH('/api/l/sublocation', {
        name: values.name,
        link: props.link,
        capacity: values.capacity ? +values.capacity : null,
        spaceBetweenGroups: values.spaceBetweenGroups ? +values.spaceBetweenGroups : null,
      });
      if (res.status === 200) {
        setSuccess(true);
        setMessage(t('success'));
        props.setData({
          name: values.name, 
          link: props.link,
          capacity: values.capacity ? +values.capacity : null,
          spaceBetweenGroups: values.spaceBetweenGroups ? +values.spaceBetweenGroups : null,
        });
        close();
      } else {setMessage(res.data.msg || res.data);}
    } catch (err) {console.error(err)}
    setSubmitting(false);
  }

  function close() {
    // setName(props.name);
    setMessage('');
    props.onClose();
  }


  function validate(values: Partial<Values>) {
    const errors: Partial<Values> = {};

    if (!values.name || values.name.length < 1) {
      errors.name = t('required');
    }
    if (values.capacity && (isNaN(+ values.capacity) || +values.capacity !== parseInt(values.capacity))) {
      errors.capacity = t('please_enter_an_integer');
    }
    if (values.spaceBetweenGroups && (isNaN(+ values.spaceBetweenGroups) || +values.spaceBetweenGroups !== parseInt(values.spaceBetweenGroups))) {
      errors.spaceBetweenGroups = t('please_enter_an_integer');
    }
    return errors;
  }



  return (
    <Dialog open={props.open} onClose={close} fullWidth maxWidth={'sm'} >
      <div className={classes.spaceBetween}>
        <DialogTitle>{t('sublocation')}</DialogTitle>
        <IconButton size={'medium'} aria-controls="menu" aria-haspopup="true" onClick={(e) => {setAnchorEl(e.currentTarget);}}><MoreVert fontSize={'inherit'} color={'inherit'} /></IconButton>
      </div>
      
      <Formik
        initialValues={{
          name: props.data.name,
          capacity: props.data.capacity ? ''+props.data.capacity : '',
          spaceBetweenGroups: props.data.spaceBetweenGroups ? ''+props.data.spaceBetweenGroups : '',
        }}
        validate={validate}
        onSubmit={submit}
        // innerRef={formikContext}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          <>
            <DialogContent>

              {/* <DialogContentText>{t('create_a_single_sublocation')}</DialogContentText> */}
              <Typography color={'error'}>{message}</Typography>
              {/* <Qrcode value={props.link} /> */}
              <TextField label={t('name')} value={values.name} onChange={handleChange('name')} error={errors.name?.length>0} helperText={errors.name} fullWidth />
              <DialogContentText style={{marginTop: theme.spacing(3), marginBottom: theme.spacing(-0.5),}}>{t('reservation_settings')}</DialogContentText>
              <div className={classes.spaceBetween}>
                <TextField label={t('capacity')} value={values.capacity} onChange={handleChange('capacity')} error={errors.capacity?.length>0} style={{flex: 1, marginRight: 6}} helperText={errors.capacity} />
                <TextField label={t('space_between_groups')} value={values.spaceBetweenGroups} onChange={handleChange('spaceBetweenGroups')} error={errors.spaceBetweenGroups?.length>0} style={{flex: 1, marginLeft: 6}} helperText={errors.spaceBetweenGroups} />
              </div>

            </DialogContent>
            <DialogActions>
              <Button color={'inherit'} onClick={close} >{t('cancel')}</Button>
              <Button color={'primary'} variant={'contained'} onClick={() => handleSubmit()} disabled={((values.name===props.data.name && +values.capacity===props.data.capacity && +values.spaceBetweenGroups===props.data.spaceBetweenGroups) || submitting || values.name==='')} >{t('save')}</Button>
            </DialogActions>
          </>
        )}
      </Formik>
      {deleteDialogComponent}
      {menuComponent}
      <Snackbar open={errorSnackBar} autoHideDuration={6000} onClose={() => {setErrorSnackBar(false)}} anchorOrigin={{vertical:'bottom', horizontal:'right'}} >
        <Alert severity="error">
          <AlertTitle>{t('an_error_occured')}</AlertTitle>
          {t('please_try_again_later')}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));
