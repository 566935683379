import React, { useState, useEffect, useContext, useRef } from 'react';
// import theme from '../theme/theme.style';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, CardContent, Avatar, Popover, TextField, Divider, ButtonBase, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { clearAll, getInitials, getItem, getRefresh, PATCH, POST, setItem } from '../api'
import { Context } from '../Store';
import validatePhoneNumber from 'validate-phone-number-node-js';
import { Formik } from 'formik';
import { Settings as SettingsIcon, LocalHospital, ArrowBackIos } from '@material-ui/icons';
import ErrorMessage from '../components/ErrorMessage';
import DeleteAccountModal from '../modals/DeleteAccountModal';

interface Values {
  name: string,
  email: string,
  phone: string,
}

interface Props {
  open: boolean,
  onClose: () => void,
}

export default (props:Props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();
  const [context, setContext] = useContext(Context);
  const [language, setLanguage] = useState(i18n.language);
  const [languageLoading, setLanguageLoading] = useState(true);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [deleteAccountDialog, setDeleteAccountDialog] = useState(false);
  const [logOutDialog, setLogOutDialog] = useState(false);

  useEffect(() => {
    setRedirect(null);
  }, [redirect]);

  useEffect(() => {
    if (!languageLoading) {
      // load language preference
      if (language !== 'default') {
        i18n.changeLanguage(language);
        setItem('language', language);
      } else {
        // const locale = (Platform.OS == 'ios' ? 
        //   NativeModules.SettingsManager.settings.AppleLocale ||
        //   NativeModules.SettingsManager.settings.AppleLanguages[0]
        // : NativeModules.I18nManager.localeIdentifier).split('_')[0];
        // i18n.changeLanguage(locale);
        // setItem('language', 'default');
      }
    } else {
      async function temp() {
        setLanguage(await getItem('language'));
      }
      temp();
    }
    setLanguageLoading(false);
  }, [language]);

  const deleteAccount = (
    <Dialog open={deleteAccountDialog} onClose={() => setDeleteAccountDialog(false)}>
      <DialogTitle>{t('delete_account')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('warning_this_action_is_not_reversable_we_may_keep_some_data_for_several_weeks_after_deletion_if_required_by_law')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color={'inherit'} onClick={() => {setDeleteAccountDialog(false)}} >{t('cancel')}</Button>
        <Button color={'primary'} onClick={() => {setDeleteAccountModal(true); setDeleteAccountDialog(false)}} >{t('delete')}</Button>
      </DialogActions>
    </Dialog>
  );

  const logout = ( 
    <Dialog open={logOutDialog} onClose={() => setLogOutDialog(false)}>
      <DialogTitle>{t('log_out')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('please_confirm_to_log_out')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color={'inherit'} onClick={() => {setLogOutDialog(false)}} >{t('cancel')}</Button>
        <Button color={'primary'} onClick={async () => {
          await POST('/api/user/logout', {refreshToken: await getRefresh()});
          setContext((context) => {
            return {...context, loggedIn: false, profile: {name: '', email: '', phone: ''}, locationOrder: [], ticketOrder: []}
          })
          await clearAll();
          setRedirect('/');
        }} >{t('log_out')}</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <CardContent className={classes.all}>
      <div>
        <div className={classes.spaceBetween}>
          <Button className={classes.leftButton} onClick={props.onClose} color={'secondary'} ><ArrowBackIos />{t('back')}</Button>
          <Button></Button>
        </div>
        <Typography className={classes.title}>{t('settings')}</Typography>
      </div>

      <FormControl >
        <FormLabel>{t('language_preferences')}</FormLabel>
        <RadioGroup value={language} onChange={(e) => setLanguage((e.target as HTMLInputElement).value)}>
          <FormControlLabel value="en" control={<Radio />} label={t('english')} />
          <FormControlLabel value="de" control={<Radio />} label={t('german')} />
        </RadioGroup>
        <FormLabel>{t('account')}</FormLabel>
        <Button onClick={() => {setLogOutDialog(true)}} color={'primary'} >{t('log_out')}</Button>
        <Button onClick={() => {setDeleteAccountDialog(true)}} color={'primary'} >{t('delete_account')}</Button>
      </FormControl>
      {logout}
      {deleteAccount}
      <DeleteAccountModal open={deleteAccountModal} onClose={() => setDeleteAccountModal(false)} />
    </CardContent>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    // justifyContent: 'left',
    // display: 'flex',
    // flexDirection: 'column',
  },
  rightButton: {
    marginTop: theme.spacing(-1),
    marginRight: theme.spacing(-1),
  },
  leftButton: {
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-1),
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: theme.spacing(-3.5),
    marginBottom: theme.spacing(2),
  },
}));
