import React, { useState, useEffect, useContext } from 'react';
// import theme from '../theme/theme.style';
import logo from '../images/logo1_large.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, Container, Grid } from '@material-ui/core';
import AppBar from '../components/AppBar';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import LocationMini from '../components/LocationMini';
import { fetchMyLocations, GET } from '../api';
import { Context } from '../Store';
import LocationModal, { useWindowDimensions } from '../modals/LocationModal';
import { Add as AddIcon } from '@material-ui/icons';
import AddLocationDialog from '../modals/AddLocationDialog';

export default () => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();
  const [context, setContext] = useContext(Context);
  const [location, setLocation] = useState<string>(null);
  const dimensions = useWindowDimensions();

  const [display, setDisplay] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [refreshTickets, setRefreshTickets] = useState(false);
  const [addDialog, setAddDialog] = useState(false);

  async function loadMyLocations() {
    setRefresh(true);
    await fetchMyLocations(setContext);
    setRefresh(false);
  }

  // async function loadTickets() {
  //   setRefreshTickets(true);
  //   const res = await GET('/api/t/20');
  //   if (res.status === 200) {
  //     for (let ticket of res.data.tickets) {
  //       setContext((context) => {
  //         return {...context, tickets: {...context.tickets, [ticket.time]: ticket}};
  //       });
  //     }
  //     setContext((context) => {
  //       return {...context, ticketOrder: res.data.tickets.map((ticket) => {return ticket.time})}
  //     });
  //   }
  //   setRefreshTickets(false);
  // }

  useEffect(() => {
    loadMyLocations();
    // loadTickets();
  }, [])

  useEffect(() => {
    setRedirect(null);
  }, [redirect]);

  return (
    <div className={classes.root}>
      <AppBar />
      <div className={classes.toolbar} />

      <Container className={classes.container} maxWidth={'lg'}>
        <Typography variant={"h1"} >{t('locations')}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={'auto'} >
            <div className={classes.locationsContainer}>
              {context.locationOrder.map((link:any, key:number) => {
                return <LocationMini link={link} key={key} onClick={(link) => {link !== location ? setLocation(link) : setLocation(null)}} selected={location} />
              })}
              <Button onClick={() => {setAddDialog(true)}} className={classes.addButton} fullWidth >
                <div className={classes.buttonContainer}>
                  <AddIcon fontSize={'default'} color={'inherit'}/>
                  <Typography style={{marginTop: 2,}} >{t('add_location')}</Typography>
                </div>
              </Button>
            </div>
            
          </Grid>
          <Grid item xs>
            <LocationModal open={location !== null} onClose={() => setLocation(null)} link={location} />
          </Grid>
        </Grid>
      </Container>
      <AddLocationDialog open={addDialog} onClose={() => {setAddDialog(false)}} />
      <Footer />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  container: {
    textAlign: 'left',
    paddingBottom: theme.spacing(2),
  },
  locationsContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    width: 400,
  },
  addButton: {
    border: '3px dashed',
    borderColor: theme.palette.grey[500],
    height: 100,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.grey[500],
    padding: theme.spacing(0.5),
  },
}));
