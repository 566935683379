import React, { useState, useEffect, useContext, useRef } from 'react';
// import theme from '../theme/theme.style';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, CardContent, Avatar, Popover, TextField, Divider, ButtonBase, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Tabs, Tab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { clearAll, DELETE, getInitials, getItem, getRefresh, PATCH, POST, setItem } from '../api'
import { Context } from '../Store';
import validatePhoneNumber from 'validate-phone-number-node-js';
import { Formik } from 'formik';
import { Settings as SettingsIcon, LocalHospital, ArrowBackIos } from '@material-ui/icons';
import ErrorMessage from '../components/ErrorMessage';

export interface Values {
  name: string,
  batchName: string,
  amount: string,
  counterStart: string,
  capacity: string,
  spaceBetweenGroups: string,
  link?: string,
}

interface Props {
  open: boolean,
  onClose: () => void,
  location: string,
  addSublocation: (name: string, link: string, capacity:number, spaceBetweenGroups:number) => void,
  customLink?: string | undefined,
  setCustomLink?: (string:string) => void,
  addBatch?: (data:Values) => void,
}


export default (props:Props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();
  const [context, setContext] = useContext(Context);

  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [link, setLink] = useState(undefined);
  const [type, setType] = useState(0);

  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  useEffect(() => {
    setMessage('');
    if (props.customLink !== undefined) {
      setLink(props.customLink);
      if(props.setCustomLink) props.setCustomLink(undefined);
    }
  }, [props.open]);

  // const [password, setPassword] = useState('');
  // useEffect(() => {
  //   setPassword('');
    
  // }, [props.open]);

  async function submit(values: Partial<Values>) {
    const currentType = type;
    setSubmitting(true);
    try {
      let res;
      if (currentType === 0) {
        res = await POST('/api/l/create/sublocation', {
          name: values.name,
          location: props.location,
          link: link,
          capacity: +values.capacity,
          spaceBetweenGroups: +values.spaceBetweenGroups,
        });
      } else {
        res = await POST('/api/l/create/batch', {
          name: values.batchName,
          location: props.location,
          amount: +values.amount,
          sublocation_name: values.name,
          counterStart: +values.counterStart,
          capacity: +values.capacity,
          spaceBetweenGroups: +values.spaceBetweenGroups,
        });
      }
      
      if (res.status === 200) {
        if (currentType === 0) {
          props.addSublocation(res.data.name, res.data.link, +values.capacity, +values.spaceBetweenGroups);
        } else {
          props.addBatch(res.data);
        }
        
        setMessage(t('success'));
        close()
      } else { setMessage(res.data.msg || res.data); }
    } catch (err) {  console.error(err) }
    setSubmitting(false);
  }

  function close() {
    setMessage('');
    setLink(undefined);
    props.onClose()
  }

  function validate(values: Partial<Values>) {
    const errors: Partial<Values> = {};

    if (!values.name || values.name.length < 1) {
      errors.name = t('required');
    }
    if (values.capacity && (isNaN(+ values.capacity) || +values.capacity !== parseInt(values.capacity))) {
      errors.capacity = t('please_enter_an_integer');
    }
    if (values.spaceBetweenGroups && (isNaN(+ values.spaceBetweenGroups) || +values.spaceBetweenGroups !== parseInt(values.spaceBetweenGroups))) {
      errors.spaceBetweenGroups = t('please_enter_an_integer');
    }

    if (type == 1) {
      if (!values.batchName || values.batchName.length < 1) {
        errors.batchName = t('required');
      }
      if (!values.amount || values.amount.length < 1 ) {
        errors.amount = t('required');
      } else if (isNaN(+ values.amount)) {
        errors.amount = t('please_enter_an_integer');
      } else if (+values.amount !== parseInt(values.amount) || parseInt(values.amount) < 1) {
        errors.amount = t('please_enter_an_integer_greater_than_zero');
      } else if ((+ values.amount) > 100) {
        errors.amount = t('number_to_large');
      }
      if (!values.counterStart || values.counterStart.length < 1 ) {
        errors.counterStart = t('required');
      } else if (isNaN(+ values.counterStart) || +values.counterStart !== parseInt(values.counterStart)) {
        errors.counterStart = t('please_enter_an_integer');
      }
    }

    return errors;
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth={'sm'} >
      <DialogTitle>{t('add_sublocation')}</DialogTitle>
      <div className={classes.center}>
        <Tabs value={type} onChange={(e, v) => {setType(v)}}>
          <Tab label={t('single')} />
          <Tab label={t('batch')} />
        </Tabs>
      </div>

      <Typography color={'error'} className={classes.message}>{message}</Typography>
      <Formik
        initialValues={{
          name: '',
          batchName: '',
          amount: '',
          counterStart: '1',
          capacity: '',
          spaceBetweenGroups: '',
        }}
        validate={validate}
        onSubmit={submit}
        // innerRef={formikContext}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          <>
            <DialogContent>
              {/* <Radio values={[t('single'), t('batch')]} selected={type} setSelected={setType} style={{marginBottom: 4}} /> */}
              
              
              {type == 0 ? <>
                <DialogContentText>{t('create_a_single_sublocation')}</DialogContentText>
                {/* {link !== undefined ? <Chip style={{alignSelf: 'center'}} onClose={() => setLink(undefined)}>{t('custom_qrcode')}</Chip>: null} */}
                <TextField label={t('name')} value={values.name} onChange={handleChange('name')} error={errors.name?.length>0} helperText={errors.name} fullWidth />
                <DialogContentText style={{marginTop: theme.spacing(3), marginBottom: theme.spacing(-0.5),}}>{t('reservation_settings')}</DialogContentText>
                <div className={classes.spaceBetween}>
                  <TextField label={t('capacity')} value={values.capacity} onChange={handleChange('capacity')} error={errors.capacity?.length>0} style={{flex: 1, marginRight: 6}} helperText={errors.capacity} />
                  <TextField label={t('space_between_groups')} value={values.spaceBetweenGroups} onChange={handleChange('spaceBetweenGroups')} error={errors.spaceBetweenGroups?.length>0} style={{flex: 1, marginLeft: 6}} helperText={errors.spaceBetweenGroups} />
                </div>
              </> : <>
                <DialogContentText>{t('create_multiple_sublocations_with_the_same_properties')}</DialogContentText>
                <TextField label={t('batch_name')} value={values.batchName} onChange={handleChange('batchName')} error={errors.batchName?.length>0} helperText={errors.batchName} fullWidth />
                <div className={classes.spaceBetween}>
                  <TextField label={t('amount')} value={values.amount} onChange={handleChange('amount')} error={errors.amount?.length>0} style={{width: '50%', marginRight: 6}} helperText={errors.amount} />
                </div>
                <div className={classes.spaceBetween}>
                  <TextField label={t('sublocation_name')} value={values.name} onChange={handleChange('name')} error={errors.name?.length>0} style={{flex: 4, marginRight: 6}} helperText={errors.name} />
                  <TextField label={t('counter')} value={values.counterStart} onChange={handleChange('counterStart')} error={errors.counterStart?.length>0} style={{flex: 1, marginLeft: 6}} helperText={errors.counterStart} />
                </div>
                <DialogContentText style={{marginTop: theme.spacing(3), marginBottom: theme.spacing(-0.5),}}>{t('reservation_settings')}</DialogContentText>
                <div className={classes.spaceBetween}>
                  <TextField label={t('capacity')} value={values.capacity} onChange={handleChange('capacity')} error={errors.capacity?.length>0} style={{flex: 1, marginRight: 6}} helperText={errors.capacity} />
                  <TextField label={t('space_between_groups')} value={values.spaceBetweenGroups} onChange={handleChange('spaceBetweenGroups')} error={errors.spaceBetweenGroups?.length>0} style={{flex: 1, marginLeft: 6}} helperText={errors.spaceBetweenGroups} />
                </div>
              </> }
            </DialogContent>
            <DialogActions>
              <Button color={'inherit'} onClick={props.onClose} >{t('cancel')}</Button>
              <Button color={'primary'} variant={'contained'} onClick={() => handleSubmit()} disabled={(submitting || values.name==="")} >{t('create')}</Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    // justifyContent: 'left',
    // display: 'flex',
    // flexDirection: 'column',
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  message: {
    // color:
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(-2),
  },
}));
