import React, { useState, useEffect } from 'react';
import { GET, isLoggedIn, removeItem } from './api';

interface Contact {
  name: string,
  email: string,
  phone: string,
}

export interface Batch {
  id: number,
  name: string,
  amount: number,
  sublocation_name: string,
  counterStart: number,
  capacity: number,
  spaceBetweenGroups: number,
}

export interface Event {
  link: string,
  name: string,
  time: string,
  userLimit: number,
  isPublic: boolean,
  booked?: number,
}

interface Ticket {
  time: string,
  booking: boolean,
  person: string,
  location_link: string,
  sublocation_link: string,
  name: string,
  email: string,
  phone: string,
}

export interface Address {
  gps: {
    lat: number,
    lon: number,
  },
  address1: string,
  address2: string,
  zipcode: string,
  city: string,
  state: string,
  country: string,
}

export interface Sublocation {
  link: string,
  name: string,
  batch: null | number,
  capacity: number,
  spaceBetweenGroups: number,
}

interface Location {
  link: string,
  type: string,
  name: string,
  address: Address,
  capacity: number,
  duration: number,
  description: string,
  sublocations: Sublocation[],
  picture: string,
  isSublocation: boolean,
  batches: Batch[],
  events: string[],
  eventsLoaded: boolean,
}

export interface ContextType {
  profile: Contact,
  contacts: {[key: string]: Contact},
  locations: {[key: string]: Location},
  locationOrder: string[],
  tickets: {[key: string]: Ticket},
  ticketOrder: string[],
  language: string,
  loaded: boolean,
  loggedIn: boolean,
  events: {[key:string]: Event},
}


export const Context = React.createContext<[ContextType, React.Dispatch<React.SetStateAction<ContextType>>]>([
  {
    profile: { name: '', email: '', phone: '', }, 
    contacts: {},
    locations: {},
    locationOrder: [],
    tickets: {},
    ticketOrder: [],
    language: 'de',
    loaded: false,
    loggedIn: false,
    events: {},
  } as ContextType, 
  (context) => null,
]);

const Store = ({ children }: any) => {
  const [context, setContext] = useState<ContextType>({
    profile: { name: '', email: '', phone: '', }, 
    contacts: {},
    locations: {},
    locationOrder: [],
    tickets: {},
    ticketOrder: [],
    language: 'de',
    loaded: false,
    loggedIn: false,
    events: {},
  } as ContextType );

  useEffect(() => {
    async function loadProfile() {
      let res = await GET('/api/user/details');
      if (res.status === 200) {
        setContext(context => ({...context, profile: {...context.profile, ...res.data}}));
        setContext((context) => {
          return {...context, loggedIn: true};
        })
      } else if (res.status === 401) {
        removeItem('accessToken');
        removeItem('refreshToken');
        setContext((context) => {
          return {...context, loggedIn: false};
        })
      }
    }
    if (isLoggedIn()) loadProfile();
  }, [])
  
  return (
    <Context.Provider value={[context, setContext]}>
      {children}
    </Context.Provider>
  );
};

export default Store;
