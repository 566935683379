import React, { useState, useEffect, useContext } from 'react';
// import theme from '../theme/theme.style';
import { Redirect, useHistory, useLocation } from 'react-router';
import logo from '../images/logo1_large.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Drawer, Divider, List, ListItem, ListItemText, ListItemIcon, Button, Avatar, Popover, Card, CardContent, Typography, MenuItem, Menu } from '@material-ui/core';
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { GET, getInitials, getItem, setItem } from '../api';
import { Context } from '../Store';
import Profile from '../modals/Profile';

const drawerWidth = 240;

export default () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>(null);
  const [context, setContext] = useContext(Context);
  const [open, setOpen] = React.useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [languageMenu, setLanguageMenu] = useState<null | HTMLElement>(null);
  const [language, setLanguage] = useState(i18n.language);
  const [languageLoading, setLanguageLoading] = useState(true);

  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  // useEffect(() => {
  //   async function loadProfile() {
  //     let res = await GET('/api/user/details');
  //     if (res.status === 200) {
  //       setContext(context => ({...context, profile: {...context.profile, ...res.data}}));
  //     }
  //   }
  //   if (isLoggedIn()) loadProfile();
  // }, [])

  useEffect(() => {
    if (!languageLoading) {
      i18n.changeLanguage(language);
      setItem('language', language);
    } else {
      async function temp() {
        setLanguage(await getItem('language'));
      }
      temp();
    }
    setLanguageLoading(false);
  }, [language]);

  const drawer = (
    <div className={classes.drawerContent}>
      <List style={{paddingTop: 0}} >
        <ListItem button onClick={() => {setOpen(false)}} className={classes.toolbar}>
          <ListItemIcon><CloseIcon/></ListItemIcon>
        </ListItem>
        <Divider />
        { !context.loggedIn ? <>
          <ListItem button onClick={() => {setRedirect('/')}}>
            <ListItemText primary={t('contactscan')} />
          </ListItem>
          <ListItem button onClick={() => {setRedirect('/support')}}>
            <ListItemText primary={t('support')} />
          </ListItem>
          <ListItem button onClick={() => {setRedirect('/scan')}}>
            <ListItemText primary={t('qrcode_scanner')} />
          </ListItem>
          <ListItem style={{flex: 1}}></ListItem>
          <Divider />
          <ListItem button onClick={() => {setRedirect('/login')}}>
            <ListItemText primary={<Typography color={'primary'} >{t('login')}</Typography>} />
          </ListItem>
          <ListItem button onClick={() => {setRedirect('/register')}}>
            <ListItemText primary={t('register')} />
          </ListItem>
        </> : <>
          <ListItem button onClick={() => {setRedirect('/main')}}>
            <ListItemText primary={t('home')} />
          </ListItem>
          <ListItem button onClick={() => {setRedirect('/locations')}}>
            <ListItemText primary={t('locations')} />
          </ListItem>
          <ListItem button onClick={() => {setRedirect('/scan')}}>
            <ListItemText primary={t('qrcode_scanner')} />
          </ListItem>
        </>}
      </List>
      
      <List>
        <Divider />
        <ListItem button onClick={(e) => {setLanguageMenu(e.currentTarget)}} aria-controls="languageMenu" aria-haspopup="true" >
          <ListItemText primary={t('change_language')} />
        </ListItem>
        <Menu
          id="languageMenu"
          anchorEl={languageMenu}
          keepMounted
          open={Boolean(languageMenu)}
          onClose={() => {setLanguageMenu(null)}}
          onClick={(e) => {console.log(e.currentTarget)}}
        >
          <MenuItem value={'en'} onClick={() => {setLanguage('en'); setLanguageMenu(null)}} >{t('english')}</MenuItem>
          <MenuItem value={'de'} onClick={() => {setLanguage('de'); setLanguageMenu(null)}} >{t('german')}</MenuItem>
        </Menu>
      </List>
    </div>
  );

  return (
      <AppBar position='fixed' className={classes.appBar} >
        {/* {redirect !== null ? <Redirect to={redirect} /> : null} */}
        <Toolbar className={classes.toolbar} >
          <div>
            <IconButton color="primary" aria-label="open drawer" edge="start" onClick={() => setOpen(!open)} className={classes.menuButton}><MenuIcon /></IconButton>
            <IconButton edge="start" className={classes.logoButton} color="inherit" disableRipple={true} onClick={() => {setRedirect('/')}} >
              <img src={logo} alt="" className={classes.logo} />
            </IconButton>
          </div>
          { !context.loggedIn ? <div>
            <Button className={classes.loginButton} variant={'text'} color={'primary'} onClick={() => {setRedirect('/login')}} >{t('login')}</Button>
            <Button className={classes.registerButton} variant={'text'} onClick={() => {setRedirect('/register')}} >{t('register')}</Button>
          </div> : <div>
            <IconButton color="primary" aria-label="profile" edge="start" onClick={(e) => {setAnchorEl(e.currentTarget); setShowProfile(!showProfile)}} className={classes.avatar}>
              <Avatar >{getInitials(context.profile.name)}</Avatar>
            </IconButton>
            <Profile open={showProfile} onClose={() => setShowProfile(false)} anchorEl={anchorEl} />
          </div>}
        </Toolbar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Drawer
            variant="temporary" anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={open}
            onClose={() => setOpen(!open)}
            classes={{ paper: classes.drawerPaper, }}
            ModalProps={{ keepMounted: true, }}
            // onClick={(e) => {console.log(e.currentTarget)}}
          >
            {drawer}
          </Drawer>
        </nav>
      </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'white',
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
      height: 32,
    },
    height: 38,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up('sm')]: {
    //   display: 'none',
    // },
  },
  logoButton: {

  },
  drawer: {

  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  toolbar: {
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  registerButton: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  loginButton: {
    [theme.breakpoints.down(331)]: {
      display: 'none',
    },
  },
  profileCard: {
    width: 350,
  },
  profileCardContent: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: 0,
    flex: 1,
  },
  avatar: {

  },
  profileAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));