import React, { useState, useEffect, useContext, useRef } from 'react';
// import theme from '../theme/theme.style';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, CardContent, Avatar, Popover, TextField, Divider, ButtonBase, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { clearAll, getInitials, getItem, getRefresh, PATCH, POST, setItem } from '../api'
import { Context } from '../Store';
import validatePhoneNumber from 'validate-phone-number-node-js';
import { Formik } from 'formik';
import { Settings as SettingsIcon, LocalHospital, ArrowBackIos } from '@material-ui/icons';
import ErrorMessage from '../components/ErrorMessage';

interface Values {
  name: string,
  email: string,
  phone: string,
}

interface Props {
  open: boolean,
  onClose: () => void,
}

export default (props:Props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();
  const [context, setContext] = useContext(Context);
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    setRedirect(null);
  }, [redirect]);


  const reportDialog = ( 
    <Dialog open={dialog} onClose={() => setDialog(false)}>
      <DialogTitle>{t('report_infection')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('we_will_notify_the_people_you_where_in_contact_with')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color={'inherit'} onClick={() => {setDialog(false)}} >{t('cancel')}</Button>
        <Button color={'primary'} onClick={async () => {
          await POST('/api/user/report', {}); 
          props.onClose()
        }} >{t('report_infection')}</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <CardContent className={classes.all}>
      <div>
        <div className={classes.spaceBetween}>
          <Button className={classes.leftButton} onClick={props.onClose} color={'secondary'} ><ArrowBackIos />{t('back')}</Button>
          <Button></Button>
        </div>
        <Typography className={classes.title}>{t('report_infection')}</Typography>
      </div>

      <Typography>{t('have_you_been_tested_positive_for_covid_19')}</Typography>
      <Typography>{t('we_will_notify_the_people_you_where_in_contact_with')}</Typography>

      <Button color={'primary'} variant={'contained'} onClick={() => setDialog(true)} >{t('report_here')}</Button>

      {reportDialog}
    </CardContent>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    // justifyContent: 'left',
    // display: 'flex',
    // flexDirection: 'column',
  },
  rightButton: {
    marginTop: theme.spacing(-1),
    marginRight: theme.spacing(-1),
  },
  leftButton: {
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-1),
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: theme.spacing(-3.5),
    marginBottom: theme.spacing(2),
  },
}));
