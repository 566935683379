import React, { useState, useEffect } from 'react';
// import theme from '../theme/theme.style';
import { Redirect, useHistory, useLocation } from 'react-router';
import logo from '../images/logo1_large.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CssBaseline, Button, Typography, Paper, Container, Link } from '@material-ui/core';
import { url } from '../api';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'formik';

const drawerWidth = 240;

interface Props {
  name: string | undefined,
}

export default (props:Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    // <ErrorMessage name={props.name?props.name:null} >{(error) => <Typography className={classes.all} key={0}>{error}</Typography>}</ErrorMessage>
    <Typography className={classes.all} key={0}>{props.name}</Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    color: theme.palette.error.main,
    textAlign: 'left',
    fontSize: 12,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 0,
  },
}));
