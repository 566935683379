import React, { useState, useEffect, useContext } from 'react';
// import theme from '../theme/theme.style';
import { Redirect, useHistory, useLocation } from 'react-router';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CssBaseline, Button, Typography, Paper, Container, Link, ButtonBase, CardActionArea, Card, CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Context, Event as EventType } from '../Store';
import { KeyboardArrowRight } from '@material-ui/icons';
import SublocationModal from '../modals/SublocationModal';
import Event from '../modals/Event';
import { GET } from '../api';

interface Props {
  locationLink: string,
  link: string,
}

export default (props:Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>(null);
  const [modal, setModal] = useState(false);

  const [context, setContext] = useContext(Context);

  function changeEvent(data:EventType) {
    console.error('not implemented');
  }

  useEffect(() => {
    // TODO reduce number of requests by getting all events at once
    async function getEvent() {
      const res = await GET(`/api/e/${props.link}`);
      setContext(context => {
        return {...context, events: {...context.events, [props.link]: res.data}}
      })
    }
    if (!context.events[props.link]) {
      getEvent();
    }
  },[])

  return (
    <Card className={classes.all}>
      <div className={classes.timeBox}>
        <Typography className={classes.date}>{context.events[props.link] && new Date(context.events[props.link]?.time).toLocaleDateString(['de', 'en'], {day: '2-digit', month:'2-digit'})}</Typography>
        <Typography className={classes.time}>{context.events[props.link] && new Date(context.events[props.link]?.time).toLocaleTimeString(['de', 'en'], {hour: '2-digit', minute:'2-digit'})}</Typography>
      </div>
      <CardActionArea onClick={() => {setModal(true)}}>
        <CardContent className={classes.spaceBetween}>
          <Typography>{context.events[props.link]?.name}</Typography>
          <KeyboardArrowRight />
        </CardContent>
      </CardActionArea>
      <Event open={modal} onClose={() => setModal(false)} locationLink={props.locationLink} link={props.link} changeEvent={changeEvent} />
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  timeBox: {
    backgroundColor: theme.palette.grey[400],
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  date: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
  },
  time: {
    color: 'white',
  },
}));
