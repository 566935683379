import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from 'react';
// import theme from '../theme/theme.style';
import { Redirect, useHistory, useLocation } from 'react-router';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CssBaseline, Button, Typography, Paper, Container, Link, ButtonBase, CardActionArea, Card, CardContent, Snackbar, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Context, Event as EventType } from '../Store';
import { Close as CloseIcon } from '@material-ui/icons';
import SublocationModal from '../modals/SublocationModal';
import Event from '../modals/Event';
import { Alert, AlertTitle } from '@material-ui/lab';

interface Props {
  severity?: 'error' | 'warning' | 'info' | 'success',
  children: string,
}

export default forwardRef((props:Props, ref:any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>(null);
  const [context, _] = useContext(Context);

  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    alert() {
      setOpen(true);
    }
  }));

  function close() {
    setOpen(false);
  }

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={close} anchorOrigin={{vertical:'bottom', horizontal:'right'}} >
        <Alert 
          severity={props.severity || 'error'}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {!props.severity || props.severity === 'error' && <AlertTitle>{t('an_error_occured')}</AlertTitle>}
          {props.children}
        </Alert>
      </Snackbar>
  );
});

const useStyles = makeStyles((theme) => ({
  all: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  timeBox: {
    backgroundColor: theme.palette.grey[400],
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  date: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
  },
  time: {
    color: 'white',
  },
}));
