import React, { useState, useEffect, useContext, useRef } from 'react';
// import theme from '../theme/theme.style';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, CardContent, Avatar, Popover, TextField, Divider, ButtonBase, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, IconButton, Menu, MenuItem, Snackbar, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Batch, Context, Event } from '../Store';
import { Field, Formik } from 'formik';
import { Add as AddIcon, FileCopy, MoreVert } from '@material-ui/icons';
import EditEventsMini from '../components/EditEventsMini';
import EventMini from '../components/EventMini';
import AddEventDialog from './AddEventDialog';
import { DELETE, PATCH, url } from '../api';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { CheckboxWithLabel } from 'formik-material-ui';
import Alert from '../components/Alert';
import copy from "clipboard-copy";

// import { Alert, AlertTitle } from '@material-ui/lab';
export interface Values {
  name: string,
  time: string,
  userLimit: string,
  isPublic: boolean | string,
  limit?: boolean,
}

interface Props {
  open: boolean,
  onClose: any,
  link: string,
  locationLink: string,
  changeEvent: (data:Event) => void,
}


export default (props:Props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();
  const [context, setContext] = useContext(Context);
  const [addDialog, setAddDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [time, setTime] = useState(new Date(context.events[props.link]?.time));
  const formik = useRef<any>();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const copyLink = useRef<any>(null);
  const alertError = useRef<any>(null);
  const alertClipboard= useRef<any>(null);

  useEffect(() => {
    setTime(new Date(context.events[props.link]?.time));
  }, [context.events[props.link]?.time])

  const deleteDialogComponent = ( 
    <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
      <DialogTitle>{t('delete_event')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('warning_this_action_is_not_reversable_we_may_keep_some_data_for_several_weeks_after_deletion_if_required_by_law')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color={'inherit'} onClick={() => {setDeleteDialog(false)}} >{t('cancel')}</Button>
        <Button color={'primary'} onClick={async () => {
          const res = await DELETE('/api/e', {link: props.link});
          if (res.status === 200) {
            // remove sublocation from context
            setContext((context) => {
              let temp = context.locations[props.locationLink].events.filter((event) => event !== props.link);
              return {...context, locations: {...context.locations, [props.locationLink]: {...context.locations[props.locationLink], events: temp}}};
            })

            // go back
            close();
          } else {
            console.log(res.data.msg || res.data)
            alertError.current?.alert();
          }
        }} >{t('delete')}</Button>
      </DialogActions>
    </Dialog>
  );

  const menuComponent = (
    <Menu
      id="menu"
      anchorEl={anchorEl}
      // keepMounted
      open={Boolean(anchorEl)}
      onClose={() => {setAnchorEl(null);}}
    >
      <MenuItem onClick={() => {setDeleteDialog(true); setAnchorEl(null);}}>{t('delete')}</MenuItem>
    </Menu>
  );

  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  function close() {
    props.onClose();
    setMessage('');
    formik?.current?.resetForm();
  }

  function validate(values: Partial<Values>) {
    const errors: Partial<Values> = {};

    if (!values.name || values.name.length < 1) {
      errors.name = t('required');
    }
    if (!time) {
      errors.time = t('required');
    }
    if (values.userLimit && (isNaN(+ values.userLimit) || +values.userLimit !== parseInt(values.userLimit))) {
      errors.userLimit = t('please_enter_an_integer');
    }

    return errors;
  }

  async function submit(values: Partial<Values>) {
    setSubmitting(true);
    try {
      let res = await PATCH('/api/e/', {
          link: props.link,
          name: values.name,
          time: time,
          userLimit: values.userLimit ? +values.userLimit : null,
          isPublic: values.isPublic,
        });
      
      if (res.status === 200) {
        props.changeEvent(res.data);

        setMessage(t('success'));
        close()
      } else {setMessage(res.data.msg || res.data)}
    } catch (err) {console.error(err)}
    setSubmitting(false);
  }

  function copyToClipboard(e:any) {
    if (document.queryCommandSupported('copy')) {
      // copyLink.current?.select();
      // document.execCommand('copy');
      copy(`${url}/e/${props.locationLink}/${props.link}`);
      e.target.focus();
      alertClipboard.current?.alert();
    } else {
      alertError.current?.alert();
    }
  }

  return (
    <Dialog open={props.open} onClose={close} fullWidth maxWidth={'sm'} >
      {/* <DialogTitle>{t('event')}</DialogTitle> */}
      {!edit ? <div className={classes.editButton}>
        <Button color={'secondary'} onClick={() => {setEdit(!edit)}} >{t('edit')}</Button>
      </div> :
      <div className={classes.spaceBetween}>
        <DialogTitle>{t('edit_event')}</DialogTitle>
        <IconButton size={'medium'} aria-controls="menu" aria-haspopup="true" onClick={(e) => {setAnchorEl(e.currentTarget);}}><MoreVert fontSize={'inherit'} color={'inherit'} /></IconButton>
      </div>}
      
      <Formik
        initialValues={{
          name: context.events[props.link]?.name || '',
          time: '',
          userLimit: context.events[props.link]?.userLimit ? ''+context.events[props.link]?.userLimit : '',
          isPublic: context.events[props.link]?.isPublic,
          limit: Boolean(context.events[props.link]?.userLimit),
        }}
        validate={validate}
        onSubmit={submit}
        innerRef={formik}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched, resetForm }) => (
          <>
            <DialogContent>   
              {edit ? <>
                <Typography color={'error'}>{message}</Typography>

                <TextField label={t('name')} value={values.name} onChange={handleChange('name')} error={errors.name?.length>0} helperText={errors.name} fullWidth />
                <div style={{height: theme.spacing(2)}} />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker value={time} onChange={setTime} format="dd.MM.yyyy HH:mm" fullWidth label={t('time')} ampm={false} helperText={errors.time} />
                </MuiPickersUtilsProvider>
                
                <div style={{height: theme.spacing(2)}} />
                <Field component={CheckboxWithLabel} type="checkbox" name="limit" Label={{ label: t("custom_limit") }} style={{alignSelf: 'left'}} />
                {values.limit ? 
                  <TextField label={t('custom_limit')} value={values.userLimit} onChange={handleChange('userLimit')} error={errors.userLimit?.length>0} helperText={errors.userLimit} fullWidth />
                :
                  <DialogContentText>{t('default_the_capacity_is_limited_by_the_capacity_of_the_sublocations')}</DialogContentText>
                }
              </> : <>
                <DialogContentText variant={'h5'}>{context.events[props.link]?.name}</DialogContentText>
                <DialogContentText>{time?.toLocaleDateString(['de', 'en'], {day: '2-digit', month:'2-digit', year:'numeric', hour: '2-digit', minute:'2-digit'})}</DialogContentText>
                
                <div className={classes.linkBox}>
                  <Link noWrap={true} href={`${url}/e/${props.locationLink}/${props.link}`} color={'inherit'} >{`${url}/e/${props.locationLink}/${props.link}`}</Link>
                  {/* <form style={{height:0, width:0}} >
                    <textarea ref={copyLink} style={{height:0, width:0}} value={`${url}/e/${props.locationLink}/${props.link}`} readOnly />
                  </form> */}
                  <IconButton onClick={copyToClipboard}><FileCopy /></IconButton>
                </div>

                <div style={{textAlign: 'left', marginTop: theme.spacing(2)}}>
                  <DialogContentText>{t('bookings')}{':'}</DialogContentText>
                </div>
              </>}

            </DialogContent>
            <DialogActions>
              <Button color={'inherit'} onClick={edit ? () => {resetForm(); setEdit(false)} : close} >{t('cancel')}</Button>
              {edit && <Button color={'primary'} variant={'contained'} onClick={() => handleSubmit()} disabled={( submitting || values.name==='')} >{t('save')}</Button>}
            </DialogActions>
          </>
        )}
      </Formik>
      {deleteDialogComponent}
      {menuComponent}
      <Alert severity={'error'} ref={alertError}>{t('please_try_again_later')}</Alert>
      <Alert severity={'info'} ref={alertClipboard}>{t('copied_to_clipboard')}</Alert>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  addButton: {
    border: '3px dashed',
    borderColor: theme.palette.grey[500],
    height: 65,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.grey[500],
    padding: theme.spacing(0.5),
  },
  editButton: {
    marginBottom: theme.spacing(-2),
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  linkBox: {
    backgroundColor: theme.palette.grey[300],
    paddingLeft: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
