import React, { useState, useEffect, useContext } from 'react';
// import theme from '../theme/theme.style';
import { Redirect, useHistory, useLocation } from 'react-router';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CssBaseline, Button, Typography, Paper, Container, Link, ButtonBase, CardActionArea, Card, CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Context, Event as EventType } from '../Store';
import { KeyboardArrowRight, Palette } from '@material-ui/icons';
import SublocationModal from '../modals/SublocationModal';
import Event from '../modals/Event';
import FlatCard from './FlatCard';

interface Sublocation {
  link: string,
  name: string,
  capacity: number,
  spaceBetweenGroups: number,
  batch: number,
  booked: number,
  groups: number,
}

interface Props {
  locationLink: string,
  sublocation: Sublocation,
  onClick: (link:string, free:number) => void,
  selected: {sublocation:string, amount:number}[],
  updateSelected: number,
}

export default (props:Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>(null);

  const [context, _] = useContext(Context);
  const [free, setFree] = useState(Math.max(0,props.sublocation.capacity-(props.sublocation.booked+(props.sublocation.spaceBetweenGroups*props.sublocation.groups))))
  const [selected, setSelected] = useState<{sublocation:string, amount:number}>(null);

  useEffect(() => {
    setSelected((selected) => {
      const temp = props.selected.filter((a)=>a.sublocation===props.sublocation.link);
      return temp.length === 0 ? null : temp[0];
    });
  }, [props.updateSelected]);

  return (
    <FlatCard className={classes.all} style={{backgroundColor: free <= 0 ? theme.palette.grey[100] : theme.palette.background.paper, borderColor: selected && theme.palette.secondary.main }}>
      <CardActionArea onClick={() => {props.onClick(props.sublocation.link, free)}} disabled={free <= 0}>
        <CardContent className={classes.spaceBetween}>
          <Typography>{props.sublocation.name}</Typography>
          <div style={{display:'flex'}}>
            <Typography color={'secondary'} style={{fontWeight: 'bold', marginRight: theme.spacing(1)}}>{selected?.amount}</Typography>
            <Typography color="textSecondary">{`(${free} ${t('free')})`}</Typography>
          </div>
          
          {/* <KeyboardArrowRight /> */}
        </CardContent>
      </CardActionArea>
    </FlatCard>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  timeBox: {
    backgroundColor: theme.palette.grey[400],
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  date: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
  },
  time: {
    color: 'white',
  },
}));
