import React, { useState, useEffect, useContext, useRef } from 'react';
// import theme from '../theme/theme.style';
import { Button, Typography, Box, Card, CardMedia, CardContent, CardActions, MenuItem, Container, useTheme, Menu, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Divider, DialogContentText, CircularProgress } from '@material-ui/core';
import { ArrowBackIos, CheckCircle, CompassCalibrationOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';
// import { ValuesOfCorrectTypeRule } from 'graphql';
import { url, GET, getItem, POST, setItem, getLocation, getEvents } from '../api';
import logo from '../images/logo1_large.svg';
import { Redirect, useHistory, useLocation } from 'react-router';
import { Batch, Context } from '../Store';
import Footer from '../components/Footer';
import validatePhoneNumber from 'validate-phone-number-node-js';
import { useTranslation } from 'react-i18next';
import AppBar from '../components/AppBar';
import Alert from '../components/Alert';
import ChooseEventMini from '../components/ChooseEventMini';
import ChooseSublocationMini from '../components/ChooseSublocationMini';
import ChooseBatchMini from '../components/ChooseBatchMini';
import { select } from 'react-cookies';
import FlatCard from '../components/FlatCard';

interface Props {
  path: string,
}

interface Values {
  name: string,
  email: string,
  amount: string,
}

interface Sublocation {
  link: string,
  name: string,
  capacity: number,
  spaceBetweenGroups: number,
  batch: number,
  booked: number,
  groups: number,
}


export default (props: Props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [locationLink, setLocationLink] = useState<string>();
  const [eventLink, setEventLink] = useState<string|null>();
  const error = useRef<any>(null);
  const selectionError = useRef<any>(null);
  const [flow, setFlow] = useState(0);

  const [redirect, setRedirect] = useState<string|null>(null);
  const [currentValues, setCurrentValues] = useState<Partial<Values>>({});
  const [context, setContext] = useContext(Context);
  const [loggedIn, setLoggedIn] = useState(false);
  const formik = useRef<any>();
  const [language, setLanguage] = useState(i18n.language);
  const [languageLoading, setLanguageLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(true);
  const [sublocations, setSublocations] = useState<Sublocation[]>([]);
  const [selected, setSelected] = useState<{sublocation:string, amount:number}[]>([]);
  const [amountSelected, setAmountSelected] = useState<number>(0);
  const [updateSelected, setUpdateSelected] = useState(0);

  useEffect(() => {
    const path = props.path.split('/');
    const locationLink = path[2];
    setLocationLink(locationLink);
    const eventLink = path.length > 3 ? path[3] : null;
    setEventLink(eventLink);

    async function fetch() {
      const locationSuccess = getLocation(locationLink, setContext);
      const eventSuccess = await getEvents(locationLink, setContext);

      if (!locationSuccess || !eventSuccess) {
        error.current?.alert();
      } else {
        setLoading(false);
      }
    }

    if (loading) fetch();

    if (context.profile.name !== undefined && context.profile.name !== '' && context.profile.email !== undefined && context.profile.email !== '') {
      setLoggedIn(true)
    }
  }, []);

  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  useEffect(() => {
    if (formik && formik.current && context.profile.name !== undefined && context.profile.name !== '' && context.profile.email !== undefined && context.profile.email !== '') {
      formik.current.handleChange('name')(context.profile.name);
      formik.current.handleChange('email')(context.profile.email);
      formik.current.handleChange('phone')(context.profile.phone);
      setLoggedIn(true)
    }
  }, [context.profile])

  useEffect(() => {
    if (!languageLoading) {
      i18n.changeLanguage(language);
      setItem('language', language);
    } else {
      async function temp() {
        setLanguage(await getItem('language'));
      }
      temp();
    }
    setLanguageLoading(false);
  }, [language]);

  useEffect(() => {
    if (flow === 1) {
      if (!eventLink) {
        error.current?.alert();
        setFlow(0);
      } else {
        getFreeSublocations();
      }
    }
  }, [flow]);

  useEffect(() => {
    if (selected.length === 0) setAmountSelected(0);
    else setAmountSelected(selected.map((a)=>a.amount).reduce((a,b)=>a+b));
  }, [updateSelected])

  function validate(values:Partial<Values>) {
    const errors: Partial<Values> = {};
    if (!values.name || values.name.length < 1) {
      errors.name = t('please_enter_your_name');
    }
    if (!values.email || !(/^\s*([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})\s*$/i.test(values.email))) {
      errors.email = t('please_enter_a_valid_email');
    }
    if (!values.amount || values.amount.length < 1){
      errors.amount = t('required');
    } else if (isNaN(+ values.amount) || +values.amount !== parseInt(values.amount)) {
      errors.amount = t('please_enter_an_integer');
    } else if (context.events[eventLink]?.userLimit) {
      const free = context.events[eventLink]?.userLimit - sublocations.map((s) => s.booked).reduce((a,b)=>a+b,0);
      if (free <= 0) {
        errors.amount = t('no_seats_left_please_choose_another_event');
      } else if (+values.amount > free) {
        errors.amount = `${t('not_enough_space_left')} ${free} ${t('seats_left')}`;
      }
    }

    if (Object.keys(errors).length > 0) {
      setLoggedIn(false);
    }
    return errors;
  }

  async function submit(values: Partial<Values>, {setSubmitting}) {
    setCurrentValues(values);
    setFlow(flow+1);
    setSubmitting(false);
  }

  async function getFreeSublocations() {
    const res = await GET(`/api/e/free/${eventLink}`);

    if (res.status === 200) {
      setSublocations(res.data.map((s:any) => {
        return {
          link: s.link,
          name: s.name,
          capacity: s.capacity ? +s.capacity : null,
          spaceBetweenGroups: s.spacebetweengroups ? +s.spacebetweengroups : null,
          batch: s.batch ? +s.batch : null,
          booked: s.booked ? +s.booked : null,
          groups: +s.groups
        }
      }));
    } else {
      error.current?.alert();
    }
  }

  const location = (
    <Card className={classes.card}>
      <Grid container style={{flex: 1}}>
        <Grid item xs={12} sm={6} style={{display: 'flex', flexDirection:'column'}} >
          <CardContent className={classes.centerCardContent}>
            <div className={classes.center}>
              <IconButton edge="start" color="inherit" disableRipple={true} href={`${url}`} /*onClick={() => {setRedirect('/')}}*/ >
                <img src={logo} alt="" className={classes.logo} />
              </IconButton>
              <Typography variant={'h5'}>{context.locations[locationLink]?.name}</Typography>
              <Typography color={'secondary'} variant={'h6'} style={{marginTop: theme.spacing(-1)}}>{t('booking')}</Typography>
              
              <div className={classes.instructions}>
                <div style={{height: theme.spacing(2)}} />
                <div className={classes.listItem}>
                  <Typography style={{width: theme.spacing(4)}} variant={'h6'} color={'secondary'} >1.</Typography>
                  <Typography>{t('choose_event')}</Typography>
                </div>
                <div className={classes.listItem}>
                  <Typography style={{width: theme.spacing(4)}} variant={'h6'} color={'secondary'} >2.</Typography>
                  <Typography>{t('enter_contact_information')}</Typography>
                </div>
                <div className={classes.listItem}>
                  <Typography style={{width: theme.spacing(4)}} variant={'h6'} color={'secondary'} >3.</Typography>
                  <Typography>{t('choose_seats')}</Typography>
                </div>
                <div className={classes.listItem}>
                  <Typography style={{width: theme.spacing(4)}} variant={'h6'} color={'secondary'} >4.</Typography>
                  <Typography>{t('recieve_confirmation')}</Typography>
                </div>
              </div>
            </div>
            
            <div className={classes.hideXs}>
              <IconButton onClick={() => {setLanguage('en')}} >🇺🇸</IconButton>
              <IconButton onClick={() => {setLanguage('de')}} >🇩🇪</IconButton>
            </div>
          </CardContent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardContent>
            <Typography color={'secondary'} variant={'h6'} className={classes.hideXs} >{t('next_events')}</Typography>
            {context.locations[locationLink]?.events?.map((link:string, key:number) => {
              return <ChooseEventMini locationLink={locationLink} link={link} key={key} onClick={(link:string) => {setEventLink(link); setFlow(1)}} />
            })}
            <div className={classes.showSm}>
              <IconButton onClick={() => {setLanguage('en')}} >🇺🇸</IconButton>
              <IconButton onClick={() => {setLanguage('de')}} >🇩🇪</IconButton>
            </div>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );

  const enterInformation = (
    <Card className={classes.card}>
      <Grid container style={{flex: 1}}>
        <Grid item xs={12} sm={6} style={{display: 'flex', flexDirection:'column'}} >
          <CardContent className={classes.centerCardContent}>
            <IconButton edge="start" color="inherit" disableRipple={true} href={`${url}`} /*onClick={() => {setRedirect('/')}}*/ >
              <img src={logo} alt="" className={classes.logo} />
            </IconButton>
              
            <DialogContentText>{t('in_the_next_step_you_choose_your_seats_there_ou_can_finalize_your_booking')}</DialogContentText>
            {/* <div style={{minWidth: '60%'}}>
              <Button color={'primary'} variant={'contained'} fullWidth>{t('login')}</Button>
              <div style={{height: theme.spacing(2)}} />
              <Button color={'primary'} variant={'text'} fullWidth>{t('register')}</Button>
            </div> */}
              
            <div className={classes.hideXs}>
              <Button onClick={() => flow > 0 && setFlow(flow-1)} startIcon={<ArrowBackIos />} color={'secondary'} >{t('back')}</Button>
              <IconButton onClick={() => {setLanguage('en')}} >🇺🇸</IconButton>
              <IconButton onClick={() => {setLanguage('de')}} >🇩🇪</IconButton>
            </div>
          </CardContent>
        </Grid>
        <Divider orientation="vertical" flexItem style={{marginTop: theme.spacing(2), marginBottom: theme.spacing(2),}} />
        <Grid item xs={12} sm={6} style={{marginLeft: -1}}>
          <CardContent>
          <Typography color={'secondary'} variant={'h6'} className={classes.hideXs} >{t('please_enter_your_information')/*t('or')*/}</Typography>

            <Formik
              initialValues={{
                name: context.profile.name,
                email: context.profile.email,
                // phone: context.profile.phone,
                amount: '',
              }}
              validate={validate}
              onSubmit={submit}
              innerRef={formik}
            >
              {({submitForm, isSubmitting, touched, errors, values}) => (
                <Form>
                  <Field component={TextField} name="name" type="name" label={t('name')} fullWidth disabled={loading} />
                  <div style={{height:theme.spacing(1)}}></div>
                  <Field component={TextField} name="email" type="email" label={t('email')} fullWidth disabled={loading} />
                  <div style={{height:theme.spacing(1)}}></div>
                  <Field component={TextField} name="amount" type="number" label={t('number_of_people')} fullWidth disabled={loading} />
                  
                  <div style={{height:theme.spacing(2)}}></div>

                  <Button variant="contained" color="primary" disabled={isSubmitting || loading} onClick={submitForm} >{t('next')}</Button>
                </Form>
              )}
            </Formik>
            <div className={classes.showSm}>
              <Button onClick={() => flow > 0 && setFlow(flow-1)} startIcon={<ArrowBackIos />} color={'secondary'} >{t('back')}</Button>
              <IconButton onClick={() => {setLanguage('en')}} >🇺🇸</IconButton>
              <IconButton onClick={() => {setLanguage('de')}} >🇩🇪</IconButton>
            </div>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );

  function select(link:string, free:number) {
    if (!selected.map((a)=>a.sublocation).includes(link)) { // select
      if (amountSelected < (+currentValues.amount)) {  // seats left
        setSelected((selected) => {
          let temp = selected;
          temp.push({sublocation:link, amount: Math.min(free, (+currentValues.amount)-amountSelected)});
          return temp;
        })
      } else {  // replace last 
        setSelected((selected) => {
          const temp = selected;
          const temp2 = temp[temp.length-1];
          if (temp2.amount > free) {
            temp[temp.length-1].amount = temp2.amount - free;
            temp.push({sublocation:link, amount: Math.min(free, (+currentValues.amount)-amountSelected)});
          } else {
            temp[temp.length-1].sublocation = link;
          }
          return temp;
        })
      }
    } else {  // deselect
      setSelected(selected.filter((a)=>a.sublocation !== link));
    }
    setUpdateSelected(updateSelected+1);
    // console.log(selected)
  }

  async function book() {
    setFlow(3);

    const res = await POST('/api/e/book', {
      name: currentValues.name,
      email: currentValues.email,
      event: eventLink,
      sublocations: selected,
    });

    if (res.status === 200) {
      setSuccess(true);
    } else {
      console.error(res.data.msg || res.data)
      selectionError.current?.alert();
      resetSelected();
      getFreeSublocations();
      setFlow(2);
    }
  }

  const chooseSublocation = (
    <Card className={classes.card}>
      <Grid container style={{flex: 1}}>
        <Grid item xs={12} sm={6} style={{display: 'flex', flexDirection:'column'}} >
          <CardContent className={classes.centerCardContent} style={{justifyContent:'flex-start'}}>
            <IconButton edge="start" color="inherit" disableRipple={true} href={`${url}`} /*onClick={() => {setRedirect('/')}}*/ >
              <img src={logo} alt="" className={classes.logo} />
            </IconButton>
              
            <FlatCard.NoBorder style={{width: '100%'}}>
              <CardMedia image={context.locations[locationLink]?.picture} className={classes.media} >
                <Typography align='left' variant='h5' className={classes.locationTitle} >{context.locations[locationLink]?.name}</Typography>
              </CardMedia>
              <FlatCard.Content>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                  <Typography align={'left'} variant={'h6'} color="secondary" >{context.events[eventLink]?.name}</Typography>
                  <div>
                    <Typography align={'right'}>{eventLink && new Date(context.events[eventLink]?.time)?.toLocaleDateString(['de', 'en'], {day: '2-digit', month:'2-digit', year:'numeric'})}</Typography>
                    <Typography align={'right'} style={{fontWeight: 'bold'}}>{eventLink && new Date(context.events[eventLink]?.time)?.toLocaleTimeString(['de', 'en'], {hour: '2-digit', minute:'2-digit'})}</Typography>
                  </div>
                </div>

                <Typography variant={'body1'} color={'textPrimary'} style={{fontWeight: 'bold'}}>{currentValues.name}</Typography>
                <Typography variant={'body2'} color={'textSecondary'}>{currentValues.email}</Typography>

                <Typography variant={'subtitle1'} style={{marginTop: theme.spacing(2)}} >{`${amountSelected}/${currentValues.amount}`}{selected.length === 0 ? ': '+t('please_choose_a_sublocation') : amountSelected<(+currentValues.amount) ? ': '+t('please_choose_another_sublocation'):null}</Typography>
                <Button variant={'contained'} color={'primary'} style={{marginTop: theme.spacing(1)}} disabled={amountSelected<(+currentValues.amount)} onClick={book}>{t('book_now')}</Button>
              </FlatCard.Content>
            </FlatCard.NoBorder>
            
            <div className={classes.hideXs}>
              <Button onClick={() => {flow > 0 && setFlow(flow-1); resetSelected();}} startIcon={<ArrowBackIos />} color={'secondary'} >{t('back')}</Button>
              <IconButton onClick={() => {setLanguage('en')}} >🇺🇸</IconButton>
              <IconButton onClick={() => {setLanguage('de')}} >🇩🇪</IconButton>
            </div>
          </CardContent>
        </Grid>
        <Divider orientation="vertical" flexItem style={{marginTop: theme.spacing(2), marginBottom: theme.spacing(2),}} />
        <Grid item xs={12} sm={6} style={{marginLeft: -1}}>
          <CardContent>
            <Typography color={'secondary'} variant={'h6'} className={classes.hideXs} >{t('seating')}</Typography>

            {/* {context.locations[locationLink]?.batches?.map((batch:Batch, key:number) => {
              return <ChooseBatchMini locationLink={locationLink} batch={batch} key={key} onClick={(id:number) => {}} />
            })} */}

            {sublocations.length <= 0 ? <CircularProgress color="secondary" /> : <>
              {sublocations?.filter((s)=> 0 < s.capacity-(s.booked+(s.spaceBetweenGroups*s.groups))).sort((a,b)=>a.name.localeCompare(b.name)).map((sublocation:Sublocation, key:number) => {
                return <ChooseSublocationMini locationLink={locationLink} sublocation={sublocation} key={key} onClick={select} selected={selected} updateSelected={updateSelected} />
              })}
              {sublocations?.filter((s)=> 0 >= s.capacity-(s.booked+(s.spaceBetweenGroups*s.groups))).sort((a,b)=>a.name.localeCompare(b.name)).map((sublocation:Sublocation, key:number) => {
                return <ChooseSublocationMini locationLink={locationLink} sublocation={sublocation} key={key} onClick={select} selected={selected} updateSelected={updateSelected} />
              })}
            </> }

            <div className={classes.showSm}>
              <Button onClick={() => {flow > 0 && setFlow(flow-1); resetSelected();}} startIcon={<ArrowBackIos />} color={'secondary'} >{t('back')}</Button>
              <IconButton onClick={() => {setLanguage('en')}} >🇺🇸</IconButton>
              <IconButton onClick={() => {setLanguage('de')}} >🇩🇪</IconButton>
            </div>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );

  function reset() {
    setFlow(0);
    resetSelected();
  }

  function resetSelected() {
    setSelected([]); 
    setAmountSelected(0);
    setUpdateSelected(updateSelected+1);
  }

  const finished = (
    <Card className={classes.card} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
      <CardContent style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}} >
        <IconButton edge="start" color="inherit" disableRipple={true} href={`${url}`} /*onClick={() => {setRedirect('/')}}*/ >
          <img src={logo} alt="" className={classes.logo} />
        </IconButton>
        {success ? <CheckCircle style={{color: theme.palette.success.main}} className={classes.checkIcon} /> : <CircularProgress color="secondary" />}
        <Button color="primary" variant={'contained'} disabled={!success} onClick={reset} >{t('finish')}</Button>
      </CardContent>
    </Card>
  );

  return (
    <div className={classes.background}>
      <AppBar />
      <div className={classes.toolbar} />

      <Container className={classes.container}>
        {/* <img src={logo} className={classes.logo} alt="" onClick={() => {setRedirect('/')}} /> */}

        {flow === 0 && location}
        {flow === 1 && enterInformation}
        {flow === 2 && chooseSublocation}
        {flow === 3 && finished}
        
      </Container>
      <Footer />
      <Alert severity={'error'} ref={error}>{t('please_try_again_later')}</Alert>
      <Alert severity={'error'} ref={selectionError}>{t('your_selection_is_already_taken')}</Alert>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  background: {
    
  },
  toolbar: {
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  container: {
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  media: {
    height: 80,
    display: 'flex',
    flexDirection: 'column-reverse',
    backgroundColor: theme.palette.grey['300'],
  },
  logo: {
    // margin: 12,
    // height: 60,
    // maxWidth: '100%'
    height: 42,
  },
  card: {
    [theme.breakpoints.up('md')]: {
      width: 800,
    },
    flex: 1,
    width: '100%',
    maxWidth: '800px',
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  centerCardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  instructions: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  showSm: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  hideXs: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  locationTitle: {
    color: 'white',
    textShadow: '1px 1px 6px rgba(0, 0, 0, 0.85)',
    marginLeft: theme.spacing(1),
  },
  locationSubTitle: {
    marginLeft: theme.spacing(1),
  },
  checkIcon: {
    marginTop: 24,
    marginBottom: 24,
    fontSize: 96,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  logoTitle: {
    fontSize: 32,
    fontWeight: 'bold',
  },
  logoTitleRed: {
    color: theme.palette.primary.main,
  },
  time: {
    textAlign: 'center',
    fontsize: 32,
    maringTop: 12,
    fontWeight: 'bold',
  },
  date: {
    textAlign: 'center',
    fontsize: 32,
    marginBottom: 24,
  },
  displayName: {
    // margin: 12,
    // marginBottom: 0,
    // fontSize: theme.font_title,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  displayEmail: {
    textAlign: "center",
    color: theme.palette.grey[800],
    fontSize: 17,
  },
  displayPhone: {
    textAlign: "center",
    color: theme.palette.grey[800],
    marginTop: 4,
    fontSize: 17,
  },
  loggedInBox: {
    marginTop: 12,
    // borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[300],
    // padding: theme.spacing(2),
  },
  changeButton: {
    // textAlign: 'right',
    // paddingHorizontal: 6,
    // color: theme.palette.grey[800],
    // fontSize: 15,
  },
}));