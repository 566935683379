import React, { useState, useEffect, useContext, useRef, Fragment, useMemo } from 'react';
// import theme from '../theme/theme.style';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, CardContent, Avatar, Popover, TextField, Divider, ButtonBase, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, IconButton, Menu, MenuItem, Link, Card, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { url } from '../api'
import { Context } from '../Store';
import { Formik } from 'formik';
import { Print } from '@material-ui/icons';
import ReactPDF, { Document, Page, Text, View, Image, StyleSheet, PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import logo from '../images/logo1.png';
import QRCode from 'qrcode'

// const { Svg } = ReactPDF;

export interface Values {
  name: string,
  capacity: string,
  spaceBetweenGroups: string,
}

interface Props {
  link: string,
}


export default (props:Props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();
  const [context, setContext] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const downloadLink = useRef<any>();

  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  const [pdfData, setPdfData] = useState<{
    loaded: boolean
    url?: string
    blob?: Blob
  }>({ loaded: false })

  function getLinks() {
    let links = [];
    const location = context.locations[props.link];
    links.push({ link: url+'/l/'+props.link, name: location.name, });

    location.sublocations.forEach(sublocation => {
      links.push({ link: url+'/l/'+sublocation.link, name: sublocation.name, });
    });

    return links;
  }

  async function generatePage(links: {name:string, link:string}[], key:number) {
    const qrCodes = []
    links.forEach(async (link, key:number) => {
      qrCodes.push(await generateQrcode(link.link, link.name, key));
    }); 

    const doc = (
      <Page size="A4" style={styles.page} key={key}>
        <View style={styles.box}>
          {qrCodes}
        </View>
      </Page>
    )
    return doc;
  }

  async function generateQrcode(link:string, name:string, key:number) {
    const size = 160;
    
    const doc = (
      <View style={styles.qrBox} key={key}>
        <Image src={logo} style={styles.logo} />
        <Image src={await QRCode.toDataURL(link, {width: size, height: size, margin: 0, errorCorrectionLevel: 'H'})} />
        <View style={styles.textBox}>
          <Text style={styles.text}>{name}</Text>
        </View>
        
      </View>
    )
    return doc;
  }

  async function generatePdf() {
    setLoading(true);
    let pages = [];
    let links = getLinks();

    // generate pages
    for (let i = 0; i < links.length; i += 16) {
      pages.push(await generatePage(links.slice(i, i+16), i));
    }

    let doc = (
      <Document>
        {pages}
      </Document>
    )

    const blobPdf = await pdf(doc);
    blobPdf.updateContainer(doc);
    const blob = await blobPdf.toBlob();
    const url = URL.createObjectURL(blob)

    setPdfData({
      loaded: true,
      url,
      blob,
    })
  }

  useEffect(() => {
    if (pdfData && downloadLink && downloadLink.current) {
      downloadLink?.current?.click();
      setLoading(false);
    }
  }, [pdfData])

  return (
    <div>
      <Button color={'secondary'} onClick={generatePdf} variant="contained" size="large" className={classes.all} startIcon={!loading ? <Print /> : <CircularProgress size={18} color="secondary" />} disabled={loading} >
        {t('print_qr_codes')}
      </Button>
      <Link href={pdfData.url!} download="contactScan.pdf" type="application/pdf" ref={downloadLink} style={{display: 'none'}} >Download</Link>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    marginTop: theme.spacing(2),
  },
  button: {
    // pading:
  },
}));

const styles = StyleSheet.create({
  page: {
    display: 'flex', 
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // fontFamily: 'Roboto, Ubuntu, sans-serif',
  },
  box: {
    "display":"flex",
    "flexDirection":"row",
    "flexWrap":"wrap",
    "width":"560pt",
    "marginTop":"21pt",
    "alignItems":"flex-start",
    "justifyContent":"flex-start"
  },
  qrBox: {
    "margin":"10pt",
    "width":"120pt",
    "height":"180pt",
  },
  instructions: {
    "backgroundColor":"lightgray",
    "margin":"10pt",
    "width":"540pt",
    "height":"180pt"
  },
  logo:{
    "width":"120pt",
    height: '40pt',
    objectFit: 'contain',
    "display":"flex",
    "alignItems":"center",
    "fontWeight":"bold",
    "fontSize":"13pt"
  },
  image:{
    "width":"36pt",
    "height":"36pt",
    "margin":"2pt",
    "marginLeft":"0pt"
  },
  text:{
    "textAlign":"center",
    "fontSize":"13pt",
    // "lineHeight":"14pt",
  },
  textBox: {
    "width":"120pt",
    "height":"20pt",
    "paddingTop":"2pt",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});
