import React, { useState, useEffect, useContext } from 'react';
// import theme from '../theme/theme.style';
import { Redirect, useHistory, useLocation } from 'react-router';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CssBaseline, Button, Typography, Paper, Container, Link, ButtonBase, CardActionArea, Card, CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Context, Sublocation } from '../Store';
import { KeyboardArrowRight } from '@material-ui/icons';
import SublocationModal from '../modals/SublocationModal';

interface Props {
  locationLink: string,
  data: Sublocation,
  setData: (data: Partial<Sublocation>) => void,
  link: string,
}

export default (props:Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>(null);
  const [modal, setModal] = useState(false);

  const [data, setData] = useState<Sublocation>(props.data);
  const [context, _] = useContext(Context);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  function changeData(newData:Partial<Sublocation>) {
    props.setData(newData);
    setData((data) => {
      return {...data, ...newData};
    })
  }

  return (
    <Card className={classes.all}>
      <CardActionArea onClick={() => {setModal(true)}}>
        <CardContent className={classes.spaceBetween}>
          <Typography>{props.data.name}</Typography>
          <KeyboardArrowRight />
        </CardContent>
      </CardActionArea>
      <SublocationModal open={modal} onClose={() => setModal(false)} locationLink={props.locationLink} data={data} link={props.link} setData={changeData} />
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));
