import React, { useState, useEffect, useContext } from 'react';
// import theme from '../theme/theme.style';
import { Redirect, useHistory, useLocation } from 'react-router';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CssBaseline, Button, Typography, Paper, Container, Link, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Context } from '../Store';

interface Props {
  link: string,
  onClick?: (link:string) => void,
  selected?: string,
}

export default (props:Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>(null);

  const [context, _] = useContext(Context);

  function onClick() {
    // if (!props.sublocationLink) {
      // navigation.navigate('displayLocation', {link: props.link, sublocationLink:undefined});
    // } else {
      // const sublocationLink = props.sublocationLink;
      // navigation.navigate('locations', {
      //   screen: 'displayLocation',
      //   params: {link: props.link, sublocationLink: sublocationLink}
      // })
      if (props.onClick) props.onClick(props.link);
    // }
  }

  return (
    <ButtonBase className={classes.all} onClick={onClick} style={props.selected === props.link ? {backgroundColor: theme.palette.secondary.main,} : null}>
      <img className={classes.image} src={context.locations[props.link].picture} alt="" />
      <Typography className={classes.locationTitle} >{context.locations[props.link].name}</Typography>
    </ButtonBase>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    backgroundColor: theme.palette.grey[100],
    height: 100,
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    paddingLeft: theme.spacing(2),
  },
  image: {
    // [theme.breakpoints.down('xs')]: {
    //   flexDirection: 'column',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    // },
    width: '100%',
    height: 100,
    objectFit: 'cover',
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  locationTitle: {
    textAlign: 'left',
    color: 'white',
    fontSize: 34,
    lineHeight: '36px',
    paddingBottom: theme.spacing(1),
    marginTop: -36 - theme.spacing(1),
    marginLeft: theme.spacing(1),
    textShadow: '0px 0px 20px rgba(0,0,0,0.69)',
  }
}));
