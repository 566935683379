import React, { useState, useEffect, useContext, useRef, Fragment, useMemo } from 'react';
// import theme from '../theme/theme.style';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, CardContent, Avatar, Popover, TextField, Divider, ButtonBase, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, IconButton, Menu, MenuItem, Link, Card, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { GET, POST, url } from '../api'
import { Context, Event } from '../Store';
import { Formik } from 'formik';
import { Event as EventIcon } from '@material-ui/icons';
import EditEventsDialog from '../modals/EditEventsDialog';

// const { Svg } = ReactPDF;

export interface Values {
  name: string,
  capacity: string,
  spaceBetweenGroups: string,
}

interface Props {
  link: string,
}


export default (props:Props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();
  const [context, setContext] = useContext(Context);
  const [modal, setModal] = useState(false);


  async function getEvents() {
    try {
      const res = await GET(`/api/e/all/${props.link}`);
      
      if (res.status === 200) {
        setContext((context) => {
          const temp = context.events;
          res.data.forEach((event:Event) => {
            temp[event.link] = event;
          });
          return {...context, events: temp, locations: {...context.locations, [props.link]: {...context.locations[props.link], eventsLoaded: true}}};
        })
      } else {console.warn(res.data.msg || res.data)}
    } catch (err) {console.error(err)}
  }

  useEffect(() => {
    if (props.link !== null && !context.locations[props.link]?.eventsLoaded && context.locations[props.link]?.events.length > 0) getEvents();
  }, [props.link])

  return (
    <div>
      <Button color={'secondary'} onClick={() => {setModal(true)}} variant="contained" size="large" className={classes.all} startIcon={<EventIcon />} >
        {t('events')}
      </Button>
      <EditEventsDialog open={modal} onClose={() => {setModal(false)}} link={props.link} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    marginTop: theme.spacing(2),
  },
  button: {
    // pading:
  },
}));
