import React, { useState, useEffect } from 'react';
// import theme from '../theme/theme.style';
// import { Redirect } from 'react-router';
import { Button, Typography, Box, Card, CardMedia, CardContent, CardActions, MenuItem, useTheme, Container } from '@material-ui/core';
// import { CheckCircle } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
// import {Formik, Form, Field} from 'formik';
// import {TextField} from 'formik-material-ui';
// import { ValuesOfCorrectTypeRule } from 'graphql';
// import { GET, POST } from '../api';
import logo from '../images/logo1_large.svg';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import Footer from '../components/Footer';

interface Props {
}

export default (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>(null);

  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  return (
    <div className={classes.background}>
      <Container className={classes.container}>
        <img src={logo} className={classes.logo} alt="" onClick={() => {setRedirect('/')}} />

        <Card className={classes.card}>
          <CardContent>
            <Typography color="primary" variant="h4" gutterBottom >{'Location not set up'}</Typography>
            {/* <Typography className={classes.disclamer} >{'Please set up to Location by scanning the QR-Code with the ContactScan App'}</Typography> */}

            <Button color="primary" variant="contained" onClick={() => {setRedirect('/login')}} >{t('login')}</Button>
          </CardContent>
        </Card>
      </Container>

      <Footer />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  background: {

  },
  container: {
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: 12,
    height: 60,
    maxWidth: '100%'
  },
  card: {
    [theme.breakpoints.up('sm')]: {
      width: 600,
    },
    flex: 1,
    width: '100%',
    maxWidth: '600px',
  },
}));