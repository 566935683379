import React, { useState, useEffect } from 'react';
// import theme from '../theme/theme.style';
// import { Redirect } from 'react-router';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Container, Link } from '@material-ui/core';
import AppBar from '../components/AppBar';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState('null');

  useEffect(() => {
    setRedirect('null');
  }, [redirect]);

  return (
    <div className={classes.root}>
      <AppBar />
      <div className={classes.toolbar} />

      <Container className={classes.container}>
        <Typography variant={"h1"} >Support</Typography>
        <Typography variant={'body1'} >
          {'If you have any Questions, you can contact us at'}{' '}
          <Link color="inherit" href="mailto:support@contactscan.de'">support@contactscan.de</Link>{'.'}
        </Typography>
      </Container>

      <Footer />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  container: {
    textAlign: 'left',
    paddingBottom: theme.spacing(2),
  },
}));
