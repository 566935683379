import React, {Children, useState, useRef, useEffect} from 'react';
import theme from '../theme/theme.style';
import { Redirect, useHistory, useLocation } from 'react-router';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as Mui from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Context, Event as EventType } from '../Store';
import { KeyboardArrowRight } from '@material-ui/icons';

const Card: React.FC<Mui.CardProps> = (props) => {
  // const { t } = useTranslation();
  // const history = useHistory();
  // const routeLocation = useLocation();
  const classes = useStyles();
  // const theme = useTheme();

  return (
    <Mui.Card {...props} classes={{'root': classes.card}}>
      {props.children}
    </Mui.Card>
  );
};

const NoBorder: React.FC<Mui.CardProps> = (props) => {
  const classes = useStyles();

  return (
    <Mui.Card {...props} classes={{'root': classes.noBorderCard}}>
      {props.children}
    </Mui.Card>
  );
};

const Content: React.FC<Mui.CardContentProps> = (props) => {
  const classes = useStyles();

  return (
    <Mui.CardContent {...props} classes={{'root': classes.content}} >
      {props.children}
    </Mui.CardContent>
  );
}

export default Object.assign(Card, { Content, NoBorder })

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: 'none',
    borderColor: theme.palette.grey[300],
    borderWidth: 2,
    borderStyle: 'solid',
  },
  content: {
    borderColor: theme.palette.grey[300],
    borderWidth: 2,
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  noBorderCard: {
    boxShadow: 'none',
  },
}));