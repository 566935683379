import React, { useState, useEffect, useContext, useRef } from 'react';
// import theme from '../theme/theme.style';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, CardContent, Avatar, Popover, TextField, Divider, ButtonBase, Card, CardMedia, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Box, Select, MenuItem, FormControl, InputLabel, Snackbar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { DELETE, getInitials, PATCH, POST } from '../api'
import { Address, Context, Batch, Sublocation } from '../Store';
import { Formik } from 'formik';
import { Add as AddIcon, ArrowBackIos, InsertPhoto } from '@material-ui/icons';
import AddSublocationModal, { Values as AddSublocationValues } from '../modals/AddSublocation';
import { Alert, AlertTitle } from '@material-ui/lab';
import SublocationMini from '../components/SublocationMini';
import LocationPicker from '../components/LocationPicker';
import PdfGenerator from '../components/PdfGenerator';
import BatchMini from '../components/BatchMini';
import EditEventsMini from '../components/EditEventsMini';

export const BREAKPOINT = 800;

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({width: window.innerWidth, height: window.innerHeight});

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({width: window.innerWidth, height: window.innerHeight});
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

interface Values {
  type: string,
  name: string,
  capacity: number,
  duration: number,
  description: string,
  picture: string,
  address: string,
}

interface Props {
  open: boolean,
  onClose: () => void,
  link: string,
}

export default (props:Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();

  const dimensions = useWindowDimensions();
  const formik = useRef<any>();

  const [context, setContext] = useContext(Context);
  const [edit, setEdit] = useState(false);
  const [addSublocationModal, setAddSublocationModal] = useState(false);
  const formikContext = useRef();
  const [picture, setPicture] = useState<any>();/*ImagePickerResponse*/
  const [uri, setUri] = useState(context.locations[props.link]?.picture);
  const [address, setAddress] = useState<Address>(context.locations[props.link]?.address);  
  const [check, setCheck] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sublocationLink, setSublocationLink] = useState(undefined);
  const [deleteLocationDialog, setDeleteLocationDialog] = useState(false);
  const [errorSnackBar, setErrorSnackBar] = useState(false);

  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  // useEffect(() => {
  //   if (props && props.sublocationLink !== undefined && props.sublocationLink) {
  //     setAddSublocationModal(true);
  //     setSublocationLink(props.sublocationLink);
  //   }
  // }, [props]);

  useEffect(() => {
    if (props.open) {
      setEdit(false);
      // setScreen('profile');
    }
  }, [props.open])

  function validate(values:Partial<Values>) {
    const errors: Partial<Values> = {};

    if (!values.type || values.type.length < 1) {
      errors.name = t('please_choose_a_type');
    }
    if (!values.name || values.name.length < 1) {
      errors.name = t('please_enter_a_name_for_the_location');
    }
    // if (!address || !address.gps || address.gps.lat === undefined || address.gps.lon === undefined) {
    //   errors.address = t('gps_required_please_activate_location_services_for_the_app_in_the_settings_go_to_contactscan_location_while_using_the_app');
    // }
    // TODO duration & capacity have to be numbers
    setCheck(true)

    return errors;
  }

  function addSublocation(name: string, link: string, capacity:number, spaceBetweenGroups:number) {
    setContext((context) => {
      const temp = context.locations[props.link].sublocations;
      temp.push({
        name: name,
        link: link,
        batch: null,
        capacity: capacity,
        spaceBetweenGroups: spaceBetweenGroups,
      });
      return {...context, locations: {...context.locations, [props.link]: {...context.locations[props.link], sublocations: temp}}}
    });
  }

  function changeSublocation(key:number, data:Partial<Sublocation>) {
    setContext((context) => {
      const temp = context.locations[props.link].sublocations;
      temp[key] = {...temp[key], ...data};
      return {...context, locations: {...context.locations, [props.link]: {...context.locations[props.link], sublocations: temp}}}
    });
  }


  function addBatch(data:any) {
    setContext((context) => {
      const temp = context.locations[props.link].batches;
      temp.push({
        id: data.id,
        name: data.name.trim(),
        amount: data.amount,
        sublocation_name: data.sublocation_name,
        counterStart: data.counterStart,
        capacity: data.capacity,
        spaceBetweenGroups: data.spaceBetweenGroups,
      });

      const temp2 = context.locations[props.link].sublocations;
      data.sublocations.forEach((sublocation:any) => {
        temp2.push({
          name: sublocation.name,
          link: sublocation.link,
          batch: data.id,
          capacity: +sublocation.capacity,
          spaceBetweenGroups: +sublocation.spaceBetweenGroups,
        });
      })

      return {...context, locations: {...context.locations, [props.link]: {...context.locations[props.link], sublocations: temp2, batches: temp}}}
    });
  }

  function setBatch(key:number, data:any) {
    setContext((context) => {
      const temp = context.locations[props.link].batches;
      temp[key] = data;

      let temp2 = context.locations[props.link].sublocations;
      temp2 = temp2.filter((a) => {return a.batch != data.id});
      temp2 = [...temp2, ...data.sublocations];

      return {...context, locations: {...context.locations, [props.link]: {...context.locations[props.link], batches: temp, sublocations: temp2}}}
    });
  }

  function changeData(newData:any) {
    setContext((context) => {
      const temp = context.locations[props.link];
      for (let key in temp) {
        if (newData[key] && newData[key] !== temp[key]) {
          temp[key] = newData[key];
        }
      }
      return {...context, locations: {...context.locations, [props.link]: temp}}
    });
  }

  async function uploadImage(link: string): Promise<boolean> {
    const payload = new FormData();
    payload.append('link', link);

    payload.append('image', file)
    // const image = {
    //   name: file.name,
    //   type: file.name.match(/\.[0-9a-z]{1,5}$/i)[0].substring(1),
    //   uri: file,
    // } as any

    // payload.append('image',image);
    changeData({picture: imagePreview})

    const res = await POST('/api/l/upload_image', payload);
    if (res.status === 200) {
      // changeData({picture: res.data.url})
      return true;
    } else {
      console.error('Image upload failed:');
      console.error(res.data.msg || res.data)
      return false;
    }
  }

  const [file, setFile] = useState<any>(null);
  const [imagePreview, setImagePreviw] = useState<string>(null);

  async function selectImage (event: any) {
    if (event.target.files[0].size >= 20971520) {
      setMessage(t('file_size_to_large'));
    } else {
      setImagePreviw(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
    }
  }

  function makeOptional(value:any):any {
    return value === undefined || value === null || value === '' ? undefined : value;
  }

  useEffect(() => {
    setAddress(context.locations[props.link]?.address)
  }, [context.locations[props.link]?.address])

  async function submit(values:any) {
    setSubmitting(true);

    const payload = {
      link: props.link,
      type: values.type,
      name: values.name,
      gps: address?.gps,
      address1: makeOptional(address?.address1),
      address2: makeOptional(address?.address2),
      zipcode: makeOptional(address?.zipcode),
      city: makeOptional(address?.city),
      state: makeOptional(address?.state),
      country: makeOptional(address?.country),
      capacity: makeOptional(values.capacity),
      duration: makeOptional(values.duration) !== undefined ? Number(makeOptional(values.duration)) : undefined ,
      description: makeOptional(values.description),
    };
    
    const res = await PATCH('/api/l', payload);
    if (res.status === 200) {
      // save locally
      changeData(payload);

      // change picture
      if (file) {
        await uploadImage(props.link);
      }

      // end edit
      // navigation.setOptions({
      //   headerShown: false,
      //   gestureEnabled: true,
      // });
      setMessage(null);
      setEdit(false);
    } else {
      setMessage(res.data.msg || res.data);
      console.error('Failed to submit location: ' + res.data)
    }
    setSubmitting(false)
  }

  function startEdit() {
    setEdit(true);
    formik?.current?.resetForm({ values: {
      type: context.locations[props.link]?.type,
      name: context.locations[props.link]?.name,
      capacity: context.locations[props.link]?.capacity || '',
      duration: context.locations[props.link]?.duration || '',
      description: context.locations[props.link]?.description || '',
    }})
  }

  function cancelEdit() {
    setEdit(false);
    // navigation.setOptions({
    //   headerShown: false,
    //   gestureEnabled: true,
    // });
    setPicture(null);
    setUri(context.locations[props.link].picture);
    setImagePreviw(null);
    setFile(null);
    setAddress(context.locations[props.link].address);
    setMessage('');
    setSuccess(false);
  }

  function deleteLocation() {
    setDeleteLocationDialog(true)
  }

  const deleteDialog = ( 
    <Dialog open={deleteLocationDialog} onClose={() => setDeleteLocationDialog(false)}>
      <DialogTitle>{t('delete_location')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('warning_this_action_is_not_reversable_we_may_keep_some_data_for_several_weeks_after_deletion_if_required_by_law')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color={'inherit'} onClick={() => {setDeleteLocationDialog(false)}} >{t('cancel')}</Button>
        <Button color={'primary'} onClick={async () => {
          const res = await DELETE('/api/l', {link: props.link});
          if (res.status === 200) {
            // remove location from context
            setContext((context) => {
              let temp = context.locationOrder.filter((link) => link !== props.link);
              return {...context, locationOrder: temp};
            })

            // go back
            close();
            setDeleteLocationDialog(false);
          } else {
            setErrorSnackBar(true);
          }
        }} >{t('delete')}</Button>
      </DialogActions>
    </Dialog>
  );

  function close() {
    // if (props.sublocationLink) navigation.dispatch(StackActions.pop(1));
    // navigation.goBack();
    props.onClose()
  }

  const locationContent = ({handleChange, handleSubmit, values, errors}) => {
    return (
      <>
        {edit? <div className={classes.spaceBetween}>
          <Button size={'large'} onClick={cancelEdit} color={'secondary'} >{t('cancel')}</Button>
          <Button size={'large'} onClick={() => {handleSubmit()}} color={'secondary'} >{t('done')}</Button>
        </div>:null}
        <Typography align="center" color={success ? 'initial':'error'}>{message}</Typography>

        {dimensions.width <= BREAKPOINT ? 
          <CardMedia image={imagePreview || context.locations[props.link]?.picture} className={classes.media} >
            {!edit ? <>
              <div className={classes.spaceBetween}>
                {dimensions.width <= BREAKPOINT ? <Button className={classes.editButton} size={'large'} onClick={close} color={'inherit'} ><ArrowBackIos />{t('locations')}</Button>:<div></div>}
                <Button className={classes.editButton} size={'large'} onClick={() => {startEdit()}} color={'inherit'} >{t('edit')}</Button>
              </div>
              <Typography align='left' variant='h4' className={classes.locationTitle} >{context.locations[props.link]?.name}</Typography>
            </> : <>
              <input style={{display: 'none'}} onChange={selectImage} type="file" accept="image/png, image/jpg, image/jpeg" id={"VideoModalUpload"} />
              <label className={classes.imagePicker} htmlFor={"VideoModalUpload"}><InsertPhoto className={classes.editButton} fontSize="large" /></label>
            </>}
          </CardMedia>
        :null}

        <div className={classes.content}>
          <Card className={props.open ? classes.card : classes.hide}>
            {dimensions.width > BREAKPOINT ? <CardMedia image={imagePreview || context.locations[props.link]?.picture} className={classes.media} >
              {!edit ? <>
                <div className={classes.spaceBetween}>
                  {dimensions.width <= BREAKPOINT ? <Button className={classes.editButton} size={'large'} onClick={close} color={'inherit'} ><ArrowBackIos />{t('locations')}</Button>:<div></div>}
                  <Button className={classes.editButton} size={'large'} onClick={() => {startEdit()}} color={'inherit'} >{t('edit')}</Button>
                </div>
                <Typography align='left' variant='h4' className={classes.locationTitle} >{context.locations[props.link]?.name}</Typography>
              </> : <>
                <input style={{display: 'none'}} onChange={selectImage} type="file" accept="image/png, image/jpg, image/jpeg" id={"VideoModalUpload"} />
                <label className={classes.imagePicker} htmlFor={"VideoModalUpload"}><InsertPhoto className={classes.editButton} fontSize="large" /></label>
              </>}
            </CardMedia>:null }
            <CardContent style={{paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1)}}>
              {!edit ? <div>
                <Typography variant={'subtitle1'} align="left" style={{fontWeight: 'bold'}}>{t('description')}</Typography>
                <Typography className={classes.label} align="left" >{context.locations[props.link]?.description ? context.locations[props.link]?.description: '\n'}</Typography>
              </div> : <div>
                <FormControl variant="filled" style={{marginBottom: theme.spacing(1)}} fullWidth size={'small'}>
                  <InputLabel>{t('type')}</InputLabel>
                  <Select value={values.type} onChange={handleChange('type')} >
                    <MenuItem value="home" >{t('home')}</MenuItem>
                    <MenuItem value="restaurant" >{t('restaurant')}</MenuItem>
                    <MenuItem value="office" >{t('office')}</MenuItem>
                    <MenuItem value="church" >{t('church')}</MenuItem>
                    <MenuItem value="school" >{t('school')}</MenuItem>
                    <MenuItem value="university" >{t('university')}</MenuItem>
                    <MenuItem value="trainStation" >{t('train_station')}</MenuItem>
                    <MenuItem value="other" >{t('other')}</MenuItem>
                  </Select>
                </FormControl>
                <TextField label={t('name')} value={values.name} onChange={handleChange('name')} error={errors.name?.length>0} helperText={errors.name} fullWidth variant={'filled'} size={'small'} style={{marginBottom: theme.spacing(1)}} />
                <TextField label={t('description')} value={values.description} onChange={handleChange('description')} error={errors.description?.length>0} helperText={errors.description} fullWidth variant={'filled'} size={'small'} />
              </div>}
              <Divider style={{marginTop: theme.spacing(0.5), marginBottom: theme.spacing(0.5)}} />
              <div className={classes.spaceBetween}>
                {!edit ? <><div style={{flex: 1}}>
                  <Typography variant={'subtitle1'} align="center" style={{fontWeight: 'bold'}} >{t('capacity')}</Typography>
                  <Typography className={classes.label} align="center">{context.locations[props.link]?.capacity}</Typography>
                </div>
                <div style={{flex: 1}}>
                  <Typography variant={'subtitle1'} align="center" style={{fontWeight: 'bold'}}>{t('duration')}</Typography>
                  <Typography className={classes.label} align="center">{context.locations[props.link]?.duration}</Typography>
                </div></> : <>
                  <TextField label={t('capacity')} value={values.capacity} onChange={handleChange('capacity')} error={errors.capacity?.length>0} helperText={errors.capacity} fullWidth variant={'filled'} size={'small'} style={{marginRight: 6}} />
                  <TextField label={t('duration')} value={values.duration} onChange={handleChange('duration')} error={errors.duration?.length>0} helperText={errors.duration} fullWidth variant={'filled'} size={'small'} style={{marginLeft: 6}} />
                </>}
              </div>
            </CardContent>
          </Card>

          <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center',}}>
            <PdfGenerator link={props.link} />
            <EditEventsMini link={props.link} />
          </div>
          
    
          {/* {!edit ? <PrintQrCodeMini link={props.link} /> : null} */}
    
          <Typography align="left" variant={'h5'} className={classes.h5} >{t('location')}</Typography>
          <Card>
            <CardContent style={{paddingBottom: theme.spacing(2)}}>
              <LocationPicker value={address} onChange={setAddress} edit={edit} error={errors.location?.length>0} helperText={errors.location} />
            </CardContent>
          </Card>
    
          <div className={classes.spaceBetween}>
            <Typography variant={'h5'} className={classes.h5} >{t('sublocation')}</Typography>
            <IconButton size={'medium'} onClick={() => {setAddSublocationModal(true)}}><AddIcon fontSize={'inherit'} color={'inherit'} /></IconButton>
          </div>
          {context.locations[props.link]?.batches !== undefined && context.locations[props.link]?.batches?.length > 0 ?
            context.locations[props.link]?.batches.sort((a, b) => {return a.name.localeCompare(b.name)}).map((batch:Batch, key:number) => {
              return <Box style={{marginBottom: theme.spacing(1)}} key={key}><BatchMini locationLink={props.link} data={batch} key={key} setBatch={(data:any) => {setBatch(key, data)}} /></Box>;
            })
          :null}
          {context.locations[props.link]?.sublocations !== undefined && context.locations[props.link]?.sublocations?.length > 0 ?
            context.locations[props.link]?.sublocations.filter((a) => {return a.batch === null}).map((sublocation:any,key:number)=>{return {...sublocation, key:key}}).sort((a, b) => {return a.name.localeCompare(b.name)}).map((subLocation:any, key:number) => {
              return <Box style={{marginBottom: theme.spacing(1)}} key={key}><SublocationMini locationLink={props.link} data={subLocation} link={subLocation.link} key={key} setData={(data)=>{changeSublocation(subLocation.key, data)}} /></Box>;
            })
          :null}
          <Button onClick={() => {setAddSublocationModal(true)}} className={classes.addButton} fullWidth >
            <div className={classes.buttonContainer}>
              <AddIcon fontSize={'default'} color={'inherit'}/>
              <Typography style={{marginTop: 2,}} >{t('add_sublocation')}</Typography>
            </div>
          </Button>
    
          {edit ? <Box style={{display: 'flex', justifyContent: 'center', marginTop: theme.spacing(1)}}>
            <Button color={'primary'} onClick={deleteLocation} >{t('delete_location')}</Button>
          </Box> : null}
        </div>
      </>
    );
  }


  return (
    <div className={props.open ? null : classes.hide}>
      <Formik
        initialValues={{
          type: context.locations[props.link]?.type,
          name: context.locations[props.link]?.name,
          capacity: context.locations[props.link]?.capacity,
          duration: context.locations[props.link]?.duration,
          description: context.locations[props.link]?.description,
        }}
        validate={validate}
        onSubmit={submit}
        innerRef={formik}
      >
        {dimensions.width > BREAKPOINT ? locationContent: 
          (formikProps) => {return (
            <Dialog fullScreen open={dimensions.width <= BREAKPOINT && props.open} onClose={close}>
              {locationContent(formikProps)}
            </Dialog>
          )}
        }
        {/* {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          // <LocationContent handleChange={handleChange} handleSubmit={handleSubmit} values={values} errors={errors} />
        )} */}
      </Formik>
      <AddSublocationModal open={addSublocationModal} onClose={() => {setAddSublocationModal(false);}} location={props.link} addSublocation={addSublocation} customLink={sublocationLink} setCustomLink={setSublocationLink} addBatch={addBatch} />
      {deleteDialog}
      <Snackbar open={errorSnackBar} autoHideDuration={6000} onClose={() => {setErrorSnackBar(false)}} anchorOrigin={{vertical:'bottom', horizontal:'right'}} >
        <Alert severity="error">
          <AlertTitle>{t('an_error_occured')}</AlertTitle>
          {t('please_try_again_later')}
        </Alert>
      </Snackbar>
    </div>
  );
};




const useStyles = makeStyles((theme) => ({
  card: {

  },
  hide: {
    display: 'none',
  },
  media: {
    height: 140,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
  },
  locationTitle: {
    color: 'white',
    textShadow: '1px 1px 6px rgba(0, 0, 0, 0.85)',
    marginLeft: theme.spacing(1),
  },
  popover: {
    [theme.breakpoints.down('xs')]: {
      left: 0,
      right: 0,
      margin: 0,
    },
  },
  label: {
    color: theme.palette.secondary.main,
    whiteSpace: 'pre-line',
  },
  h5: {
    marginTop: theme.spacing(1),
  },
  addButton: {
    border: '3px dashed',
    borderColor: theme.palette.grey[500],
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.grey[500],
    padding: theme.spacing(0.5),
  },
  editButton: {
    color: 'white',
    textShadow: '1px 1px 6px rgba(0, 0, 0, 0.85)',
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: theme.spacing(-3.5),
    marginBottom: theme.spacing(2),
  },
  spaceBetweenWithMargin:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 6,
  },
  imagePicker: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#00000033',
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
  }
}));
