import React, { useState, useEffect, useContext } from 'react';
// import theme from '../theme/theme.style';
import { Redirect, useHistory, useLocation } from 'react-router';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CssBaseline, Button, Typography, Paper, Container, Link, ButtonBase, CardActionArea, Card, CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Batch, Context } from '../Store';
import { KeyboardArrowRight } from '@material-ui/icons';
import SublocationModal from '../modals/SublocationModal';
import BatchModal from '../modals/BatchModal';

interface Props {
  locationLink: string,
  data: Batch,
  setBatch: (data:Batch) => void,
}

export default (props:Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>(null);
  const [modal, setModal] = useState(false);

  const [context, _] = useContext(Context);

  return (
    <Card className={classes.all}>
      <CardActionArea onClick={() => {setModal(true)}}>
        <CardContent className={classes.spaceBetween}>
          <Typography>{props.data.name}</Typography>
          <KeyboardArrowRight />
        </CardContent>
        <div className={classes.bottom}>
          <Typography>{props.data.sublocation_name}{' '}{props.data.counterStart}{' - '}{props.data.counterStart+props.data.amount-1}</Typography>
        </div>
      </CardActionArea>
      <BatchModal open={modal} onClose={() => setModal(false)} locationLink={props.locationLink} data={props.data} setBatch={props.setBatch} />
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bottom: {
    backgroundColor: theme.palette.grey[400],
    paddingLeft: theme.spacing(2),
    color: 'white',
  },
}));
