import React, { useState, useEffect } from 'react';
// import theme from '../theme/theme.style';
import logo from '../images/logo1_large.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, Container } from '@material-ui/core';
import AppBar from '../components/AppBar';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import QrReader from 'react-qr-reader'
import { useWindowDimensions } from '../modals/LocationModal';

export default () => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();
  const [warn, setWarn] = useState(false)
  const dimensions = useWindowDimensions();

  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  function read(data) {
    if (data !== null) {
      console.log(data)
      setWarn(! /^https?:\/\/(www\.)?contactscan\.[a-zA-Z]+(\/[a-zA-Z0-9]+)*\/?$/g.test(data))

      const locationPattern = /^https?:\/\/(www\.)?contactscan\.de\/l\/[a-zA-Z0-9]+$/g;
      // 'http://www.contactscan.de/l/Y1M7Z4'

      if (locationPattern.test(data)) {
        setRedirect('/l/'+ (/\/l\/[a-zA-Z0-9]+$/g.exec(data)[0]).substring(3));
        // navigation.navigate('ticket', {link: (/\/l\/[a-zA-Z0-9]+$/g.exec(e.data)[0]).substring(3)})
      }
    }
  }

  return (
    <div className={classes.root}>
      <AppBar />
      <div className={classes.toolbar} />

      <Container className={classes.container}>
        <Typography variant={"h1"} >Scan</Typography>
        <Typography align={'center'} variant={'h5'} color={'primary'} style={{marginBottom: theme.spacing(1)}} >{t('scan_a_contactscan_qrcode')}</Typography>
        <div className={classes.qrContainer}>
          <QrReader
            delay={10}
            style={{
              width: '100%',
              maxWidth: '90vh',
            }}
            onError={(err) => {console.error(err)}}
            onScan={read}
            resolution={1000}
          />
        </div>
        
      </Container>

      <Footer />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  container: {
    textAlign: 'left',
    paddingBottom: theme.spacing(2),
  },
  qrContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
