import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// const languageDetector = {
//   type: 'languageDetector',
//   async: true,
//   detect: (cb:any) => cb('en'),
//   init: () => {},
//   cacheUserLanguage: () => {},
// };

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    resources: {
      en: {translation: require('./locales/en.json')} ,
      de: {translation: require('./locales/de.json')}
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;