import React, { useState, useEffect, useContext, useRef } from 'react';
// import theme from '../theme/theme.style';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, CardContent, Avatar, Popover, TextField, Divider, ButtonBase, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { clearAll, DELETE, getInitials, getItem, getRefresh, PATCH, POST, setItem } from '../api'
import { Context } from '../Store';
import validatePhoneNumber from 'validate-phone-number-node-js';
import { Formik } from 'formik';
import { Settings as SettingsIcon, LocalHospital, ArrowBackIos } from '@material-ui/icons';
import ErrorMessage from '../components/ErrorMessage';

interface Values {
  password: string,
}

interface Props {
  open: boolean,
  onClose: () => void,
}

export default (props:Props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();
  const [context, setContext] = useContext(Context);

  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const [password, setPassword] = useState('');
  useEffect(() => {
    setPassword('');
    setMessage('');
  }, [props.open]);

  async function submit() {
    setSubmitting(true);
    try {
      const res = await DELETE('/api/user/', {
        password: password,
      });
      setPassword('');
      if (res.status === 200) {
        setMessage(t('success'));
        await clearAll();
        setRedirect('/');
        // navigation.navigate('auth');
        // setRedirect('/loading');
      } else {
        setMessage(res.data.msg || res.data);
      }
    } catch (err) { console.error(err) }
    setSubmitting(false);
  }

  function validate(values: Partial<Values>) {
    const errors: Partial<Values> = {};

    if (!values.password || !(/^.{6,}$/i.test(values.password))) {
      errors.password = t('password_has_to_be_at_least_6_characters_long');
    }

    return errors;
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{t('delete_account')}</DialogTitle>
      <Typography color={'error'} className={classes.message}>{message}</Typography>
      <Formik
        initialValues={{
          name: context.profile.name,
          email: context.profile.email,
          phone: context.profile.phone,
        }}
        validate={validate}
        onSubmit={submit}
        // innerRef={formikContext}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          <>
            <DialogContent>
              <DialogContentText>{t('i_sure_hope_you_know_what_you_are_doing_please_enter_your_password_to_delete_your_account')}</DialogContentText>
              <TextField className={classes.input} label={t('password')} value={values.password} onChange={handleChange('password')} fullWidth error={errors.password !== undefined} helperText={errors.password} variant="filled" size="small" type={'password'} />
            </DialogContent>
            <DialogActions>
              <Button color={'inherit'} onClick={props.onClose} >{t('cancel')}</Button>
              <Button color={'primary'} onClick={() => handleSubmit()} >{t('delete')}</Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    // justifyContent: 'left',
    // display: 'flex',
    // flexDirection: 'column',
  },
  rightButton: {
    marginTop: theme.spacing(-1),
    marginRight: theme.spacing(-1),
  },
  leftButton: {
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-1),
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: theme.spacing(-3.5),
    marginBottom: theme.spacing(2),
  },
  input: {

  },
  message: {
    // color:
  }
}));
