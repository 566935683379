import React, { useState, useEffect, useContext, useRef } from 'react';
// import theme from '../theme/theme.style';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, CardContent, Avatar, Popover, TextField, Divider, ButtonBase, Card, CardMedia, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Box, Select, MenuItem, FormControl, InputLabel, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { DELETE, getInitials, PATCH, POST } from '../api'
import { Address, Context } from '../Store';
import validatePhoneNumber from 'validate-phone-number-node-js';
import { Formik } from 'formik';
import { Add as AddIcon, ArrowBackIos, InsertPhoto } from '@material-ui/icons';
import ErrorMessage from '../components/ErrorMessage';
import Settings from '../components/Settings';
import ReportInfections from '../components/ReportInfections';
import AddSublocationModal, { Values as AddSublocationValues } from '../modals/AddSublocation';
import { Alert, AlertTitle } from '@material-ui/lab';
import SublocationMini from '../components/SublocationMini';
import LocationPicker, { Location } from '../components/LocationPicker';

export const BREAKPOINT = 800;

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({width: window.innerWidth, height: window.innerHeight});

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({width: window.innerWidth, height: window.innerHeight});
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

interface Values {
  type: string,
  name: string,
  capacity: string,
  duration: string,
  description: string,
  picture: string,
  address: string,
}

interface Props {
  open: boolean,
  onClose: () => void,
  // link: string,
}

export default (props:Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();

  const dimensions = useWindowDimensions();
  const formik = useRef<any>();
  const [link, setLink] = useState(undefined);
  const [context, setContext] = useContext(Context);
  // const [edit, setEdit] = useState(false);
  const [addSublocationModal, setAddSublocationModal] = useState(false);
  const formikContext = useRef();
  const [picture, setPicture] = useState<any>();/*ImagePickerResponse*/
  // const [uri, setUri] = useState(context.locations[props.link]?.picture);
  const [address, setAddress] = useState<Address>();
  const [check, setCheck] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sublocationLink, setSublocationLink] = useState(undefined);
  const [deleteLocationDialog, setDeleteLocationDialog] = useState(false);
  // const [location, setLocation] = useState<Location>({
  //     gps: {
  //       lat: undefined,
  //       lon: undefined,
  //     },
  //     address1: undefined,
  //     address2: undefined,
  //     zipcode: undefined,
  //     city: undefined,
  //     state: undefined,
  //     country: undefined,
  //   });

  useEffect(() => {
    if (redirect !== null && routeLocation.pathname !== redirect) history.push(redirect);
    setRedirect(null);
  }, [redirect]);

  function reset() {
    formik?.current?.resetform();
    setPicture(null);
    setImagePreviw(null);
    setFile(null);
    // setUri('');
    setAddress({
      gps: {
        lat: undefined,
        lon: undefined,
      },
      address1: undefined,
      address2: undefined,
      zipcode: undefined,
      city: undefined,
      state: undefined,
      country: undefined,
    });
  }


  // function validate(values:Partial<Values>) {
  //   const errors: Partial<Values> = {};

  //   if (!values.type || values.type.length < 1) {
  //     errors.name = t('please_choose_a_type');
  //   }
  //   if (!values.name || values.name.length < 1) {
  //     errors.name = t('please_enter_a_name_for_the_location');
  //   }
  //   // if (!address || !address.gps || address.gps.lat === undefined || address.gps.lon === undefined) {
  //   //   errors.address = t('gps_required_please_activate_location_services_for_the_app_in_the_settings_go_to_contactscan_location_while_using_the_app');
  //   // }
  //   // TODO duration & capacity have to be numbers
  //   setCheck(true)

  //   return errors;
  // }
  function validate(values: Partial<Values>) {
    const errors: Partial<Values> = {};

    if (!values.type || values.type.length < 1) {
      errors.name = t('please_choose_a_type');
    }
    if (!values.name || values.name.length < 1) {
      errors.name = t('please_enter_a_name_for_the_location');
    }
    // if (!picture) {
    //   errors.picture = t('please_upload_or_take_a_picture');
    // }
    if (!file) {
      errors.picture = t('required');
    }
    if (!address || !address.gps || !address.gps.lat || !address.gps.lon) {
      errors.address = t('required');
    }
    // TODO duration & capacity have to be numbers
    setCheck(true)

    return errors;
  }


  async function uploadImage(link: string): Promise<boolean> {
    const payload = new FormData();
    payload.append('link', link);

    payload.append('image', file)
    // const image = {
    //   name: file.name,
    //   type: file.name.match(/\.[0-9a-z]{1,5}$/i)[0].substring(1),
    //   uri: file,
    // } as any

    // payload.append('image',image);
    // changeData({picture: imagePreview})

    const res = await POST('/api/l/upload_image', payload);
    if (res.status === 200) {
      // changeData({picture: res.data.url})
      return true;
    } else {
      console.error('Image upload failed:');
      console.error(res.data.msg || res.data)
      return false;
    }
  }

  const [file, setFile] = useState<any>(null);
  const [imagePreview, setImagePreviw] = useState<string>(null);

  async function selectImage (event: any) {
    if (event.target.files[0].size >= 20971520) {
      setMessage(t('file_size_to_large'));
    } else {
      setImagePreviw(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
    }
  }

  function makeOptional(value:any):any {
    return value === undefined || value === null || value === '' ? undefined : value;
  }

  // async function submit(values:any) {
  //   setSubmitting(true);

  //   const payload = {
  //     link: props.link,
  //     type: values.type,
  //     name: values.name,
  //     gps: address?.gps,
  //     address1: makeOptional(address?.address1),
  //     address2: makeOptional(address?.address2),
  //     zipcode: makeOptional(address?.zipcode),
  //     city: makeOptional(address?.city),
  //     state: makeOptional(address?.state),
  //     country: makeOptional(address?.country),
  //     capacity: makeOptional(values.capacity),
  //     duration: makeOptional(values.duration) !== undefined ? Number(makeOptional(values.duration)) : undefined ,
  //     description: makeOptional(values.description),
  //   };
    
  //   const res = await PATCH('/api/l', payload);
  //   if (res.status === 200) {
  //     // save locally
  //     changeData(payload);

  //     // change picture
  //     if (file) {
  //       await uploadImage(props.link);
  //     }

  //     // end edit
  //     // navigation.setOptions({
  //     //   headerShown: false,
  //     //   gestureEnabled: true,
  //     // });
  //     setMessage(null);
  //     setEdit(false);
  //   } else {
  //     setMessage(res.data.msg || res.data);
  //     console.error('Failed to submit location: ' + res.data)
  //   }
  //   setSubmitting(false)
  // }

  async function submit(values:Values) {
    setSubmitting(true);

    const payload = {
      type: values.type,
      name: values.name,
      gps: address.gps,
      address1: makeOptional(address.address1),
      address2: makeOptional(address.address2),
      zipcode: makeOptional(address.zipcode),
      city: makeOptional(address.city),
      state: makeOptional(address.state),
      country: makeOptional(address.country),
      capacity: makeOptional(values.capacity),
      duration: makeOptional(values.duration),
      description: makeOptional(values.description),
      link: link,
    };
    
    const res = await POST('/api/l/create', payload);
    if (res.status === 200) {
      setMessage(res.data.msg || res.data);
      await uploadImage(res.data.link);

      setContext((context) => {
        const temp = context.locations;
        temp[res.data.link] = {
          link: res.data.link,
          type: values.type,
          name: values.name,
          address: address,
          picture: imagePreview,
          capacity: makeOptional(values.capacity),
          duration: makeOptional(values.duration),
          description: makeOptional(values.description),
          sublocations: [],
          isSublocation: false,
          batches: [],
          events: [],
          eventsLoaded: true,
        };
        const temp2 = context.locationOrder;
        temp2.unshift(res.data.link);
        return {...context, locations: temp, locationOrder: temp2}
      })
      // Go to location
      close();
      reset();
      // navigation.navigate('displayLocation', {link: res.data.link})
      // navigation.dispatch(
      //   StackActions.replace('displayLocation', {link: res.data.link})
      // );
      // props.goToLocation(res.data.link);
    } else {
      setMessage(res.data.msg || res.data);
      console.error('Failed to submit location: ' + res.data)
    }
    setSubmitting(false)
  }

  function close() {
    props.onClose()
  }

  return (
    <Dialog fullWidth maxWidth={'sm'} open={props.open} onClose={close} fullScreen={useMediaQuery(theme.breakpoints.down('sm'))} classes={{paper: classes.dialog}} >
      <Formik
        initialValues={{
          type: 'home',
          name: '',
          capacity: '',
          duration: '15',
          description: '',
          picture: '',
          address: '',
        }}
        validate={validate}
        onSubmit={submit}
        innerRef={formik}
      >
        {({handleChange, handleSubmit, values, errors}) => (
          <>
            <DialogTitle>{t('add_location')}</DialogTitle>
            <Typography align="center" color={'error'}>{message}</Typography>

            <DialogContent>
              <Card>
                <CardMedia image={imagePreview} className={classes.media} >
                  <input style={{display: 'none'}} onChange={(e) => {selectImage(e); handleChange('picture')(e.target.files[0].name)}} type="file" accept="image/png, image/jpg, image/jpeg" id={"VideoModalUpload"} />
                  <label className={classes.imagePicker} htmlFor={"VideoModalUpload"}><InsertPhoto className={classes.editButton} fontSize="large" /></label>
                </CardMedia>
              </Card>
              <ErrorMessage name={errors.picture} />

              <Card style={{marginTop: theme.spacing(2)}}>
                <CardContent>
                  <FormControl variant="filled" style={{marginBottom: theme.spacing(1)}} fullWidth size={'small'}>
                    <InputLabel>{t('type')}</InputLabel>
                    <Select value={values.type} onChange={handleChange('type')} >
                      <MenuItem value="home" >{t('home')}</MenuItem>
                      <MenuItem value="restaurant" >{t('restaurant')}</MenuItem>
                      <MenuItem value="office" >{t('office')}</MenuItem>
                      <MenuItem value="church" >{t('church')}</MenuItem>
                      <MenuItem value="school" >{t('school')}</MenuItem>
                      <MenuItem value="university" >{t('university')}</MenuItem>
                      <MenuItem value="trainStation" >{t('train_station')}</MenuItem>
                      <MenuItem value="other" >{t('other')}</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField label={t('name')} value={values.name} onChange={handleChange('name')} error={errors.name?.length>0} helperText={errors.name} fullWidth variant={'filled'} size={'small'} style={{marginBottom: theme.spacing(1)}} />
                  {/* <Divider style={{marginTop: theme.spacing(0.5), marginBottom: theme.spacing(0.5)}} /> */}
                  <div className={classes.spaceBetween}>
                    <TextField label={t('capacity')} value={values.capacity} onChange={handleChange('capacity')} error={errors.capacity?.length>0} helperText={errors.capacity} fullWidth variant={'filled'} size={'small'} style={{marginRight: 6}} />
                    <TextField label={t('duration')} value={values.duration} onChange={handleChange('duration')} error={errors.duration?.length>0} helperText={errors.duration} fullWidth variant={'filled'} size={'small'} style={{marginLeft: 6}} />
                  </div>
                </CardContent>
              </Card>

              <Card style={{marginTop: theme.spacing(2)}}>
                <CardContent style={{paddingBottom: theme.spacing(2)}}>
                  <LocationPicker value={address} onChange={(e) => {setAddress(e); handleChange('address')('true')}} edit={true} error={errors.address?.length>0} helperText={errors.address} />
                </CardContent>
              </Card>

              <Card style={{marginTop: theme.spacing(2)}}>
                <CardContent>
                  <TextField multiline label={t('description')} value={values.description} onChange={handleChange('description')} error={errors.description?.length>0} helperText={errors.description} fullWidth variant={'filled'} size={'small'} />
                </CardContent>
              </Card>
            </DialogContent>

            <DialogActions>
              <Button color={'inherit'} onClick={close} >{t('cancel')}</Button>
              <Button color={'primary'} variant={'contained'} onClick={() => handleSubmit()} disabled={(submitting || values.name==="")} >{t('create')}</Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};


const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.background.default,
  },
  card: {

  },
  hide: {
    display: 'none',
  },
  media: {
    height: 140,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
  },
  locationTitle: {
    color: 'white',
    textShadow: '1px 1px 6px rgba(0, 0, 0, 0.85)',
    marginLeft: theme.spacing(1),
  },
  popover: {
    [theme.breakpoints.down('xs')]: {
      left: 0,
      right: 0,
      margin: 0,
    },
  },
  label: {
    color: theme.palette.secondary.main,
    whiteSpace: 'pre-line',
  },
  h5: {
    marginTop: theme.spacing(1),
  },
  addButton: {
    border: '3px dashed',
    borderColor: theme.palette.grey[500],
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.grey[500],
    padding: theme.spacing(0.5),
  },
  editButton: {
    color: 'white',
    textShadow: '1px 1px 6px rgba(0, 0, 0, 0.85)',
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: theme.spacing(-3.5),
    marginBottom: theme.spacing(2),
  },
  spaceBetweenWithMargin:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 6,
  },
  imagePicker: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#00000033',
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
  }
}));
