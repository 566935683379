import React, { useState, useEffect, useContext } from 'react';
// import theme from '../theme/theme.style';
import logo from '../images/logo1_large.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Typography, Container } from '@material-ui/core';
import AppBar from '../components/AppBar';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Context } from '../Store';

export default () => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>();
  const [context, setContext] = useContext(Context);

  useEffect(() => {
    setRedirect(null);
  }, [redirect]);

  useEffect(() => {
    if (context.loaded && context.loggedIn) {
      setRedirect('/main');
    }
  }, [context.loaded])

  return (
    <div className={classes.root}>
      <AppBar />
      <div className={classes.toolbar} />

      <Container className={classes.container}>
        <Typography variant={"h1"} >Home</Typography>
        <div style={{height: 200}}>Please scan a QR code</div>
      </Container>

      <Footer />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  container: {
    textAlign: 'left',
    paddingBottom: theme.spacing(2),
  },
}));
