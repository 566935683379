import React, { useState, useEffect, useContext } from 'react';
// import theme from '../theme/theme.style';
import { Redirect, useHistory, useLocation } from 'react-router';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CssBaseline, Button, Typography, Paper, Container, Link, ButtonBase, CardActionArea, Card, CardContent, Dialog, DialogTitle, IconButton, DialogContent, TextField, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Context } from '../Store';
import { KeyboardArrowRight, LocationOn } from '@material-ui/icons';
import { Formik, FormikErrors } from 'formik';
import { isConstructorDeclaration } from 'typescript';
import { getAddress, getGeolocation } from '../api';

interface Values {
  location: string,
}

export interface Location {
  gps: {
    lat: number,
    lon: number,
  },
  address1: string,
  address2: string,
  zipcode: string,
  city: string,
  state: string,
  country: string,
}

interface Props {
  value: Location,
  onChange: (location: Location) => void,
  helperText?: string | FormikErrors<any> | string[] | FormikErrors<any>[],
  error?: boolean,
  edit?: boolean,
}

export default (props:Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const routeLocation = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [redirect, setRedirect] = useState<string|null>(null);
  const [context, _] = useContext(Context);

  const [dialog, setDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState<string>();
  const [locations, setLocations] = useState<any[]>([]);

  async function useGPS() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const res = await getAddress(position.coords.latitude, position.coords.longitude);
        if (res.status === 200) {
          chooseLocation(res.data);
        } else {
          setMessage(t('something_went_wrong_please_try_again_later'));
        }
      })
    } else {
      setMessage(t('geolocation_not_available'));
    }
  }

  function makeDefined(value:any, alt?:any):any {
    return value !== undefined && value !== '' && value !== null ? value : alt;
  }

  async function submit(values: Partial<Values>) {
    setSubmitting(true);
    let res = await getGeolocation(values.location);
    if (res.status === 200) {
      if (res.data.length === 0) {
        setMessage(t('address_not_found_check_your_input'))
      } else if (res.data.length === 1) {
        setLocations(res.data);
        chooseLocation(res.data[0]);
      } else {
        setLocations(res.data);
      }
    } else {
      setMessage(t('something_went_wrong_please_try_again_later'))
    }
    setSubmitting(false);
  }

  function close() {
    setDialog(false);
  }

  function validate(values: Partial<Values>) {
    const errors: Partial<Values> = {};

    if (!values.location || values.location.length < 1) {
      errors.location = t('required');
    }

    return errors;
  }

  function chooseLocation(location:any) {
    props.onChange({
      gps: {lat:location.lat, lon:location.lon}, 
      address1: location.display_name, 
      address2: null,
      zipcode: null,
      city: null,
      state: null,
      country: null,
    });
    close();
  }

  return (
    <div className={classes.all} >
      <div className={classes.content} onClick={() => {setDialog(props.edit)}}>
        <div className={classes.mapBox}>
          <LocationOn fontSize={'large'} />
        </div>
        <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
          <Typography color={'secondary'} className={classes.address1}>{makeDefined(props.value?.address1, t('add_an_address'))}</Typography>
          {/* {props.value?.address2 !== undefined && props.value?.address2 !== null && props.value?.address2 !== '' ? <Typography>{makeDefined(props.value?.address2)}</Typography>:null}
          <Typography>{makeDefined(props.value?.zipcode, t('postal_code')) + ', ' + makeDefined(props.value?.city, t('city')) + ', ' + makeDefined(props.value?.state, t('state'))}</Typography>
          <Typography>{makeDefined(props.value?.country, t('country'))}</Typography> */}
        </div>
        <div className={classes.icon} style={!props.edit ? {display: 'none'} : null}>
          <KeyboardArrowRight color={'inherit'} fontSize={'default'} />
        </div>
      
      </div>
      <Typography className={props.error ? classes.warningText : classes.hidden}>{props.helperText}</Typography>
      {/* <LocationPickerModal visible={modal} close={() => setModal(false)} location={props.location} setLocation={props.setLocation} getGPS={getGPS} /> */}
      
      <Dialog open={dialog} onClose={close} fullWidth maxWidth={'sm'} classes={{'paper': classes.dialog }} >
        <Formik
          initialValues={{
            location: '',
          }}
          validate={validate}
          onSubmit={submit}
          // innerRef={formikContext}
        >
          {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
            <>
              <DialogTitle>{t('location_picker')}</DialogTitle>
              <DialogContent>

                {/* <DialogContentText>{t('create_a_single_sublocation')}</DialogContentText> */}
                <Typography align={'center'} color={'error'}>{message}</Typography>
                {/* <Qrcode value={props.link} /> */}
                <TextField label={t('enter_an_address_or_use_your_location')} onKeyDown={(e)=>{if(e.key==='Enter')handleSubmit()}} value={values.location} onChange={handleChange('location')} error={errors.location?.length>0} helperText={errors.location} variant={'outlined'} fullWidth />
                
                {locations.length > 0 ?
                  <div className={classes.box}>
                    {locations.map((location:any, key:number) => {
                      return (<ButtonBase onClick={() => {chooseLocation(location)}} key={key} className={classes.displayLocation}>
                        <Typography>{location.display_name}</Typography>
                      </ButtonBase>);
                    })}
                  </div>
                :null}
                <Link align="left" href="https://osm.org" color={'inherit'} className={classes.link}>{t('powered_by_osm')}</Link>

                <div className={classes.spaceBetween}>
                  <Button color={'primary'} onClick={useGPS} disabled={submitting} ><LocationOn /> {t('use_my_location')}</Button>
                  <Button color={'primary'} variant={'contained'} onClick={() => handleSubmit()} disabled={values.location==='' || submitting} >{t('search')}</Button>
                </div>

              </DialogContent>
              <DialogActions>
                <Button color={'inherit'} onClick={close} >{t('cancel')}</Button>
                {/* <Button color={'primary'} variant={'contained'} onClick={() => handleSubmit()} disabled={values.location===''} >{t('done')}</Button> */}
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  all: {
    
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  content:{
    display: 'flex',
    flexDirection: 'row',
  },
  mapBox: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[600],
    marginRight: 12,
    borderRadius: theme.shape.borderRadius,
    width: 80,
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  address1: {
    fontWeight: 'bold',
  },
  warningText: {
    marginTop: 4,
    color: theme.palette.error.main,
  },
  hidden: {
    display: 'none',
  },
  icon: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    // backgroundColor: '#00ff0055'
  },
  box: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  displayLocation: {
    padding: theme.spacing(2),
    width: '100%',
  },
  dialog: {
    backgroundColor: theme.palette.background.default,
  },
  link: {
    color: theme.palette.grey[600],
    fontSize: 13,
    marginBottom: -12,
  }
}));
