import { createMuiTheme } from '@material-ui/core/styles';
// import purple from '@material-ui/core/colors/purple';
// import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(201, 10, 19)' //'#006611',
    },
    secondary: {
      main: 'rgb(32, 120, 251)', //'#ff8269',
    },
  },
  typography: {
    h1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: '34pt',
      lineHeight: 1.2,
      paddingLeft: -4,
      marginTop: 8,
    },
    h2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: '32pt',
      lineHeight: 1.13,
    },
    h3: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: '24pt',
      lineHeight: 1.17,
    },
    h4: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: '20pt',
      lineHeight: 1.2,
    },
    // h5: {
    //   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    //   fontWeight: 600,
    //   fontSize: '20pt',
    //   lineHeight: 1.2,
    // },
    // h6: {
    //   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    //   fontWeight: 600,
    //   fontSize: '18pt',
    //   lineHeight: 1.11,
    // },
    // subtitle1: {
    //   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    //   fontWeight: 600,
    //   fontSize: '18pt',
    //   lineHeight: 1.4,
    // },
    // subtitle2: {
    //   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    //   fontWeight: 600,
    //   fontSize: '16pt',
    //   lineHeight: 1.3,
    // },
    small: 13,
    medium: 15,
    large: 17,
  },
  shape: {
    borderRadius: 8,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;